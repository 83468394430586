import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

import HomePage from '../views/Home.vue';
import LoginPage from '../views/Login.vue';
import DashboardPage from '../views/Dashboard.vue';
import MapPage from '../views/Map.vue';
import LeafletPage from '../views/Leaflet.vue';
import RealEstateAssetDetailPage from '../views/RealEstateAssetDetail.vue';
import RealEstateAssetMapPage from '../views/RealEstateAssetMap.vue';

const parsingId = (route) => ({ id: Number(route.params.id) })

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/leaflet',
    name: 'Leaflet',
    component: LeafletPage
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardPage
  },
  {
    path: '/map',
    name: 'Map',
    component: MapPage,
    props: true,
    
  },
  {
    path: '/real-estate-asset/:id',
    name: 'RealEstateAssetDetail',
    component: RealEstateAssetDetailPage,
    props: true,
  },
  {
    path: '/real-estate-asset/:id/map',
    name: 'RealEstateAssetMap',
    component: RealEstateAssetMapPage,
    props: true,
  },

  // otherwise redirect to home
  { 
    //path: '*',
    path: "/:catchAll(.*)",
    redirect: '/' 
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  //console.log('router', {from:from, to:to});
  const publicPages = ['/login', '/register'];
  const authRequired = !publicPages.includes(to.path);
  const logPage = publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  if(logPage && loggedIn )
  {
    return next('/dashboard');
  }

  next();
})


export default router
