  <template>
  <div  class="item">
    <div class="icon" :style="{'background-image' :  'url(' + iconStyle+')','background-position': 'bottom','background-repeat': 'no-repeat','background-size': 'contain'}"></div>
    <div class="label label-value" slot="end">{{formattedValue}}</div>
    <div class="label label-name" slot="start">{{label}}</div>
  </div>
</template>

<script lang="ts">
import {  IonItem, IonLabel} from '@ionic/vue';
import { defineComponent } from 'vue';

import moment from 'moment';
import numeral from 'numeral';


export default defineComponent({
  name: 'data-key-item',
  components: {
    
  },
  props:
  {
    type: String,
    label: String,
    value: String,
  },
  setup() {  
    return {  };
  },
  computed: {
    iconStyle(): any
    {
      switch(this.type)
      {
        case 'RealEstateAssetsCount' : return 'assets/img/Pictos/building-line.png';
        case 'RentalArea' : return 'assets/img/Pictos/bar-chart-2-line.png';
        
        case 'Rent' : return 'assets/img/Pictos/calendar-2-line.png';

        case 'TenantCount' : return 'assets/img/Pictos/group-line.png';
        case 'Walt' : return 'assets/img/Pictos/percent-line.png';
        
        case 'BuyingPrice' : return 'assets/img/Pictos/hand-coin-line.png';
        case 'Occupant_Tenant' : return 'assets/img/Pictos/group-line.png';
        case 'BuyingDate' : return 'assets/img/Pictos/calendar-2-line.png';
        case 'ConstructionYear' : return 'assets/img/Pictos/hammer-line.png';
        case 'Status' : return 'assets/img/Pictos/list-check.png';
        


        case 'Investment' : return 'assets/img/Pictos/money-euro-circle-line.png';
        case 'Efficiency' : return 'assets/img/Pictos/wallet-3-line.png';
        case 'Distribution' : return 'assets/img/Pictos/file-chart-line.png';
        

      }
      
      return 'assets/img/Pictos/information-line.png';
    },
    formattedValue(): any
    {
      this.$i18n.locale;
      switch(this.type)
      {
        //case 'RealEstateAssetsCount' : return 'assets/img/Pictos/building-line.png';
        case 'Rent' :
        case 'Investment' :
        case 'Distribution' :
        case 'BuyingPrice' : 
        case 'RentalArea' : return numeral(Number(this.value)).format('0[.]00a');
        case 'Walt' : return numeral(Number(this.value)).format('0[.]00');
        case 'BuyingDate' : return moment(this.value).format('L');
        
      }
      return this.value;
    }
  },
  methods: {
    
  },
});
</script>

<style scoped>

.item
{
  margin: 10px 2px;
  width: 105px;
}


.icon
{
  margin: 0px 10px;
  
  height: 4em;
}
.label
{
  margin: 5px 10px;
  
  padding: 0;
  text-align: center;
}

.label-name
{
  
  font-size: 0.8em;
}

.label-value
{ 
  font-size: 1.2em;
  font-weight: 700;
}

.left
{
  float: left;
}

.right
{
  float: right;
}

@media(min-width:540px)
{
  .item
  {
    margin: 15px 5px;
    width: 125px;
  }

  .label-name
  {
    
    font-size: 1em;
  }
}


</style>