
import {  
  IonSlides,
  IonSlide,
  IonCard,
  modalController
  } from '@ionic/vue';

import { defineComponent } from 'vue';

import UserMessageItem from './UserMessageItem.vue';

import UserMessageModal from './UserMessageModal.vue'


//import {UserMessageInterface} from '../_interfaces/UserMessage';

export default defineComponent({
  name: 'user-messages',
  components: {
    IonSlides, IonSlide,
    UserMessageItem,
    IonCard,

  },
  props: {
    messages: {type:Array}
  },
  data()
  {
    const slideOpts = {
      initialSlide: 0,
      speed: 400,
      slidesPerView: 'auto',
      spaceBetween: 30,
      centeredSlides: true,
      
    };


    return {slideOpts,}
  },
  setup() {  
    

    return { 
      //slideOpts,
      //messages : 5
     };
  },
  methods: {
    async showMessage(m: any)
    {
      const modal = await modalController
        .create({
          component: UserMessageModal,
          cssClass: 'my-custom-class',
          componentProps: {
            message: m,
          },
        })
      return modal.present();
    }
  },
  mounted()
  {
    //mySwiper.reInit(true)
    console.log("mounted usermesage", (this.$refs.mySwiper as any).$el);
    
    //this.$nextTick( () => { (this.$refs.mySwiper as any).$el.update()});
    setTimeout( () => { (this.$refs.mySwiper as any).$el.update()}, 500 );
    //this.$nextTick( () => { (this.$refs.mySwiper as any).$el.pagination.update()});
    //this.$nextTick().then(() =>this.$nextTick(  () => { (this.$refs.mySwiper as any).$el.update()}));
  },
  
});
