import config from './config';
import { authHeader } from '../_helpers';

import { Plugins } from '@capacitor/core';


import axios, { Method } from 'axios';

const { Device } = Plugins;




function handleResponse(response): Promise<any> {
    return new Promise( (resolve, reject) =>
    {
        console.log('axios response', response);
        const data = response.data ;//&& JSON.parse(response.data);
        if(response.status !== 200) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return reject(error);
        }

        return resolve(data);
    });
}

function handleError(error) {
    console.log('axios error', error);
    
    if (error.response.status === 401) {
        console.log('axios error not logged in', error.response);
        throw(error);
        // auto logout if 401 response returned from api
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        //logout();
        //location.reload(true);
    }

}




async function login(email, password) {

    const info = await Device.getInfo();
    console.log(info);
    
    
    const requestOptions  = {
        method: 'post' as Method,
        url: `${config.apiUrl}/login`,
        headers: { 'Content-Type': 'application/json' },
        data : { 
            email : email,
            password : password,
            application:info.platform +' - '+ info.operatingSystem+'_'+info.osVersion+'@'+info.manufacturer,
            device:info.name ? info.name : 'default',
         },
        

    };

    return axios(requestOptions)
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        .then(handleResponse)
        .then(data => {
            // login successful if there's a jwt token in the response
            if (data.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('token', JSON.stringify(data.token));
            }

            if (data.data) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(data.data));
            }


            return { user : data.data, token : data.token};
        });
}


function logout() {
    const requestOptions = {
        method: 'POST' as Method,
        url : `${config.apiUrl}/logout`,
        headers: authHeader(),
        
    };

    return axios(requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch( () => {
            console.log("user service logout error");
        }).finally(() => {
            //console.log("user service logout finally");
            localStorage.removeItem('user');
            localStorage.removeItem('company');
            localStorage.removeItem('portfolios');
            localStorage.removeItem('realEstateAssets');
            localStorage.removeItem('messages');
            localStorage.removeItem('token');
        });

}

function getUser() {
    const requestOptions = {
        method: 'GET'as Method,
        url : `${config.apiUrl}/user`,
        headers: authHeader()
    };
    
    return axios(requestOptions)
    .then(handleResponse)
    .then(data => {
        localStorage.setItem('user', JSON.stringify(data.data));
        return data.data;
    })
    .catch(handleError);
}


function getCompany() {
    const requestOptions = {
        method: 'GET'as Method,
        url : `${config.apiUrl}/company`,
        headers: authHeader()
    };
    
    return axios(requestOptions)
    .then(handleResponse)
    .then(data => {
        localStorage.setItem('company', JSON.stringify(data.data));
        return data.data;
    })
    .catch(handleError);
}

function getPortfolios() {
    const requestOptions = {
        method: 'GET'as Method,
        url : `${config.apiUrl}/portfolios`,
        headers: authHeader()
    };
    
    return axios(requestOptions)
    .then(handleResponse)
    .then(data => {
        localStorage.setItem('portfolios', JSON.stringify(data.data));
        return data.data;
    })
    .catch(handleError);
}

function getMessages() {
    const requestOptions = {
        method: 'GET'as Method,
        url : `${config.apiUrl}/messages`,
        headers: authHeader()
    };
    
    return axios(requestOptions)
    .then(handleResponse)
    .then(data => {
        localStorage.setItem('messages', JSON.stringify(data.data));
        return data.data;
    })
    .catch(handleError);
}


function getRealEstateAssets() {
    const requestOptions = {
        method: 'GET'as Method,
        url : `${config.apiUrl}/real_estate_assets`,
        headers: authHeader()
    };
    
    return axios(requestOptions)
    .then(handleResponse)
    .then(data => {
        localStorage.setItem('realEstateAssets', JSON.stringify(data.data));
        return data.data;
    })
    .catch(handleError);
}


export const userService = {
    login,
    logout,
    getUser,
    getCompany,
    getPortfolios,
    getMessages,
    getRealEstateAssets,
};