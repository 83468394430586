
import { IonContent, IonPage, IonButton, IonLabel, IonSlides, IonSlide, loadingController, IonSegment, IonSegmentButton } from '@ionic/vue';
import { defineComponent } from 'vue';

import { mapState, mapActions } from 'vuex'

import WelcomePopup from '../components/WelcomePopup.vue';

import  DataKey from '../components/DataKey.vue';

import  LangBtn from '../components/LangBtn.vue';
import  DashboardBtn from '../components/DashboardBtn.vue';

//import  LMap from '../components/map/LMap.vue';
//import  LMarker from '../components/map/LMarker.vue';

import {combileRealEstateAssetsData} from '../_helpers/combineRealEstateAssetsData';

import {cloneDeep as _cloneDeep, filter as _filter, sortBy as _sortBy  } from 'lodash';

import {
  LMap,
  LIcon,
  LTileLayer,
  //LControlZoom,
  //LControlAttribution,
  LMarker,
  //LControlLayers,
  //LTooltip,
  LPopup,
  //LPolyline,
  //LPolygon,
  //LRectangle,
  //LCircleMarker,
} from "@vue-leaflet/vue-leaflet";

//import LMarkerClusterGroup from '../components/map/LMarkerClusterGroup.vue';


import "leaflet/dist/leaflet.css";
import config from '@/_services/config';
//import { bounds } from 'leaflet';
//import { Map } from 'leaflet';



export default defineComponent({
  name: 'Map',
  components: {
    WelcomePopup,
    IonContent,
    //IonRefresher, IonRefresherContent,
    IonPage,
    IonButton,
    IonLabel,
    IonSlides, IonSlide,
    IonSegment, IonSegmentButton,
    LangBtn,
    DashboardBtn,

    LMap,
    LMarker,
    LPopup,
    LIcon,
    //LCircleMarker,
    LTileLayer,
    //LControlZoom,
    //LControlAttribution,

    //LMarkerClusterGroup,
    
    DataKey
  },
  props:
  {
    display:{
      type: String,
      required: false,
      //default: 'map',
      //validator: function (value: string) {
        // La valeur passée doit être l'une de ces chaines de caractères
      //  return ['map', 'list'].indexOf(value) !== -1
      //}

    }
  },
  data() {
    const slideOpts = {
      initialSlide: 0,
      pager:false,
      speed: 200,
      slidesPerView: 'auto',
      spaceBetween: 50,
      centeredSlides: true,
      
      };
    //console.log(this);
    const currentAsset: any = null;
    const showWelcome = false;
    const markerState = false;
    const loading: HTMLIonLoadingElement|any = null;
    
    const markerIconOnUrl = 'assets/img/MarkerMap_ON_R2.png';
    const markerIconOffUrl = 'assets/img/MarkerMap_OFF_R2.png';
    return {
      currentAsset,
      segmentValue:'map',
      //segmentValue: this.$props['display'],
      slideOpts,
      showWelcome,
      markerState,
      markerIconOnUrl,
      markerIconOffUrl,
      loading,
      mapCenter :[47.41322, -1.219482],
      mapZoom : 15,
      
    };
  },
  setup() {  

    return { 
      //segmentValue:$props['display'],
      messages : 10
     };
  },
  computed: {
    ...mapState<any>({
        user: state =>  state.authentication.user,
        realEstateAssets: state =>  state.authentication.realEstateAssets,
        currentAssetPreview: function(){
          if(this.currentAsset?.preview)
          {
            if(this.currentAsset.preview.generated_conversions?.thumb512)
              return config.assetsUrl+this.currentAsset.preview.generated_conversions?.thumb512;

            return config.assetsUrl+this.currentAsset.preview.public_url;
          }
          return null;
        },
        allAssetsData: function(){
          return this.allAssetsDataFunction();
        },
        currentAssetMetadata :function()
        {
          return this.processRealEstateAssetsMetadata(this.currentAsset);
        }
    })
  },
  watch:{
    currentAsset:function()
    {
      if(this.segmentValue === 'list')
        return;

      console.log("currentAsset change",this.currentAsset );
      this.syncMap();
      this.syncSlide();

      
    }
  },
  methods: {
    ...mapActions('authentication', {
        getUser: 'getUser',
        getRealEstateAssets: 'getRealEstateAssets',
    }),
    hideWelcome()
    {
      this.showWelcome = false;
    },
    doRefresh(event: CustomEvent)
    {
      if(event.target !== null)
      {
        /*
        //this.getUser().finally(() => (event.target as unknown as typeof IonRefresher).complete());  
        Promise.all([this.getUser(),this.getRealEstateAssets()]).then(values => {
          //console.log(values);
          (event.target as unknown as typeof IonRefresher).complete();  
        }).catch(reason => {
          //console.log(reason);
          (event.target as unknown as typeof IonRefresher).complete();
        });
        */
      }
    },
    markerClick($item)
    {
      //console.log('markerClick', this.$refs.map);
      this.currentAsset = $item;
      
    },
    onClickAllAssets()
    {
      this.currentAsset = null;
    },
    SelectRealEstateAsset( $item)
    {
      //console.log('SelectRealEstateAsset', $item);
      this.currentAsset = $item;
    },
    async presentLoading() {
      
      if(this.loading !== null)
        return;

      this.loading = await loadingController
        .create({
          cssClass: 'app-loading',
          message: this.$t('loading.pleaseWait'),
          //duration: this.timeout,
        });

      await this.loading.present();

    },
    dismissLoading()
    {
      if(this.loading !== null)
      {
        this.loading.dismiss();
        this.loading = null;
      }
    },
    async slideDidChange()
    {
      console.log("slideDidChange 1"); 
      console.log("slideDidChange 2", (this.$refs.slides as any).$el.getActiveIndex());
      
      (this.$refs.slides as any).$el.getActiveIndex().then( (i) => {
        
        if(i==0)
        {
          this.currentAsset = null;
        }else
        {
          this.currentAsset = this.realEstateAssets[i-1];
        }
        
      });
      
    },
   
    syncSlide(speed?: number | undefined)
    {
      console.log("sync Slide");
       
      if(this.currentAsset !== null)
      {
        const index = this.realEstateAssets.findIndex((i) => i.id==this.currentAsset.id);
        (this.$refs.slides as any).$el.slideTo(index+1,speed);

      }
      else
      {
          //console.log("zooming to all point");
          (this.$refs.slides as any).$el.slideTo(0,speed);
      }
    },
    syncMap()
    {
      if(this.currentAsset !== null)
      {
        //console.log("zooming to",this.currentAsset.gps_coordinates );
        //console.log("map",(this.$refs.map as typeof LMap).leafletObject );
        (this.$refs.map as typeof LMap).leafletObject.setView([this.currentAsset.gps_coordinates.latitude,this.currentAsset.gps_coordinates.longitude],16);
        setTimeout( () => {this.syncPopup();},500);
      }
      else
      {
          this.syncPopup();
          this.fitToAll();
      }
    },
    syncPopup()
    {
      if(this.currentAsset != null)
      {
        console.log(this.$refs);
        
        const p = (this.$refs["markerPopup_"+this.currentAsset.id] as typeof LPopup).leafletObject;
        const m = (this.$refs.map as typeof LMap).leafletObject;
        console.log(p);
        console.log(m);
        p.openPopup();
        //m.openPopup(p);
      }else
      {
        
        const m = (this.$refs.map as typeof LMap).leafletObject;
        m.closePopup();
      }
    },
    mapReady()
    {
      //console.log("leaflet map is ready");
      (this.$refs.map as typeof LMap).leafletObject.attributionControl.remove();
      (this.$refs.map as typeof LMap).leafletObject.zoomControl.remove();
      //this.$nextTick( () =>
      //(this.$refs.map as typeof LMap).leafletObject.invalidateSize());
      setTimeout(()=> 
      {
        
         (this.$refs.map as typeof LMap).leafletObject.invalidateSize();
         //console.log((this.$refs.map as typeof LMap).leafletObject.zoomControl);
         //(this.$refs.map as typeof LMap).leafletObject.attributionControl.remove();
         //(this.$refs.map as typeof LMap).leafletObject.zoomControl.remove();
         //this.$nextTick( () =>this.fitToAll());
         this.fitToAll();
      }, 500);
      
    },
    fitToAll()
    {
      const b: Array<any> = [];
      for(const element of this.realEstateAssets)
      {
        b.push([element.gps_coordinates.latitude, element.gps_coordinates.longitude]);
      }

      console.log("bounds" , b);
      //console.log("map-bounds" , this.$refs.mapBounds);
      //console.log("map-bounds" , this.$refs.mapBounds as HTMLElement);
      //console.log("map-bounds" , (this.$refs.mapBounds as any).getClientRects());
      //console.log("map-bounds" , (this.$refs.mapBounds as any).getBoundingClientRect());

      const mapBounds = (this.$refs.mapBounds as any).getBoundingClientRect();
      (this.$refs.map as typeof LMap).leafletObject.fitBounds(b,{padding: [mapBounds.left, mapBounds.top]});

      
  
    },
    handleCmdMouseDown()
    {
      //console.log('handle Cmd Mouse Down');
    },
    handleCmdMouseUp()
    {
      //console.log('handle Cmd Mouse Up');
    },
    allAssetsDataFunction()
    {
          
          const r = combileRealEstateAssetsData(this.realEstateAssets,this.$i18n.locale );
          r.push( {
            combineType: "none",
            combineFormat: null,
            key: "RealEstateAssetsCount",
            type: "number",
            order: 0,
            name: {en: "Assets", fr: "Actifs"},
            unit: null,
            // eslint-disable-next-line @typescript-eslint/camelcase
            raw_value: this.realEstateAssets.length,
            value: {
              en:this.realEstateAssets.length,
              fr:this.realEstateAssets.length,
            },
            values: [],
          });
          return _sortBy(r,[function(o) { return o.order; }]) ;
    },
    getRealEstateAssetsPreview(r)
    {
      if(r.preview)
          {
            if(r.preview.generated_conversions?.thumb512)
              return config.assetsUrl+r.preview.generated_conversions?.thumb512;

            return config.assetsUrl+r.preview.public_url;
          }
      return null;
    },
    processRealEstateAssetsMetadata(r, useFilter: string[]|null = null)
    {
      if(r?.metadatas)
          {
            let m = _cloneDeep(r.metadatas);
            const c = _filter(r.metadatas, function(o){return o.key == 'Occupant_Tenant'}).length;
            m.push( {
              combineType: "sum",
              key: "TenantCount",
              type: "number",
              order: 20,
              name: {en: "Tenant count", fr: "Nombre de locataires"},
              unit: null,
              // eslint-disable-next-line @typescript-eslint/camelcase
              raw_value: c,
              value: {
                en:c,
                fr:c,
              },
              values: [],
            });

            if(useFilter != null)
            {
              m =  _filter(m, function(o){return useFilter.includes(o.key) });
            }
            //console.log('currentAssetMetadata',m);
            return _sortBy(m,[function(o) { return o.order; }]) ;
          }
          return null;
    }
  },
  async ionViewWillEnter()
  {
    //console.log('display', this.display);
    //console.log('display router', this.$route);
    //if(this.display != null)
    if( this.$route.params.display != null)
    {
      this.segmentValue = this.$route.params.display as string;
    }
  },
  async ionViewDidEnter() {
    //console.log('Home page did enter');
    //document.title='Rori Partners - Map';

    await this.presentLoading();
    console.log('ionViewDidEnter enter'); 
    //(this.$refs.slides as any).$el.update();
    //this.syncSlide(0);
     
    Promise.all([this.getUser(), this.getRealEstateAssets()]).finally(() => {
      
      this.dismissLoading(); 
      //console.log("reset slide");
      //this.$nextTick( () => { (this.$refs.slides as any).$el.update(); this.syncSlide();});
    });
  },
  
});
