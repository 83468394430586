

import { defineComponent } from 'vue';
//import axios, { Method } from 'axios';
import { Method } from 'axios';
import axios from './OverpassAxiosInterceptor';

import {
  //LMap,
  LIcon,
  //LTileLayer,
  //LControlZoom,
  //LControlAttribution,
  //LFeatureGroup,
  LMarker,
  //LControlLayers,
  //LTooltip,
  //LPopup,
  //LPolyline,
  //LPolygon,
  //LRectangle,
  //LCircleMarker,
} from "@vue-leaflet/vue-leaflet";


export default defineComponent({
  name: 'leaflet-overpass',
  components: {
    //LFeatureGroup,
    LMarker,
    //LPopup,
    LIcon,
  },
  props: {
    endPoint: {
        type:String,
        default: 'https://overpass-api.de/api/',
        },
    query: {
        type:String,
        default: '(node({{bbox}})[organic];node({{bbox}})[second_hand];);out qt;'
        },
    bounds: {type:Object},
    markerIconOn: {type: [ Object, String ]},
    markerIconOff: {type: [ Object, String ]},
    timeout: {type:Number, default : 30 * 1000}, // Milliseconds
    retryOnError: {type:Boolean, default : false},
    retryOnTimeout: {type:Boolean, default : false},
    noInitialRequest:  {type:Boolean, default : false},
    createPopup:  {type:Boolean, default : false},
    iconSize:  {type:Number, default : 40},
    selectedNode: {type:Number},
  },
  emits: [
    'on-marker-click',
    'on-loading'
    ],
  data() {
    
    const elements: any = null;
    
    return {
      elements,  
    };
  },
  async created () {
    if(!this.noInitialRequest)
    {
      this.sendRequest();  
    }
  },
  methods: {
    
    markerClick(m: any)
    {
      //console.log("[Emit on-marker-click]",m);
      this.$emit('on-marker-click',m);
    },
    buildOverpassQueryFromQueryAndBounds(_query: string, _bounds: any) {
        //const sw = _bounds._southWest;
        //const ne = _bounds._northEast;
        //const coordinates = [sw.lat, sw.lng, ne.lat, ne.lng].join(',');
        const sw = _bounds[0];
        const ne = _bounds[1];
        const coordinates = [sw[0], sw[1], ne[0], ne[1]].join(',');

        _query = _query.replace(/(\/\/.*)/g, '');
        _query = _query.replace(/(\{\{bbox\}\})/g, coordinates);

        return _query;
    },
    buildOverpassUrlFromEndPointAndQuery(_endPoint: string, _query: string) {
        return `${_endPoint}interpreter?data=[out:json];${_query}`;
    },
    sendRequest()
    {
        if(this.elements != null)
          return;

        const url = this.buildOverpassUrlFromEndPointAndQuery(
                            this.endPoint ,
                            this.buildOverpassQueryFromQueryAndBounds(
                            this.query,
                            this.bounds
                            )
                            );
        
        const requestOptions  = {
            method: 'get' as Method,
            url: url,
            timeout : this.timeout,
            //headers: { 'Content-Type': 'application/json' },
        };

        this.$emit('on-loading',true);
        axios(requestOptions)
        .then(response => {
            //console.log("response", response);
            //if(response.status == 200)
            {
                this.elements = response.data.elements;
            }
            
        })
        .catch( error =>
        {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.error(error.response.data);
              console.error(error.response.status);
              console.error(error.response.headers);
              
              if(this.retryOnError)
              {
                this.sendRequest();
              }


            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.error(error.request);
              
              if(this.retryOnTimeout)
              {
                this.sendRequest();
              }
              
            } else {
              // Something happened in setting up the request that triggered an Error
              console.error('Error', error.message);
            }
            console.error(error.config);
            
            
        })
        .finally( () =>
          {
            this.$emit('on-loading',false);
          }
        );
    },
    
  },
});
