const configServer =
{
    prod :
    {
        apiUrl: 'https://roripartners.tailora.fr/api',
        assetsUrl: 'https://roripartners.tailora.fr/',
        resetPasswordUrl: 'https://roripartners.tailora.fr/password/reset',
    },
    dev :
    {
        apiUrl: 'https://roripartners.dev.tailora.fr/api',
        assetsUrl: 'https://roripartners.dev.tailora.fr/',
        resetPasswordUrl: 'https://roripartners.dev.tailora.fr/password/reset',
    }
};



export default {
    //apiUrl: 'https://roripartners.tailora.fr/api',
    //assetsUrl: 'https://roripartners.tailora.fr/',
    //resetPasswordUrl: 'https://roripartners.tailora.fr/password/reset',
    apiUrl: (process.env.NODE_ENV === 'production') ? configServer.prod.apiUrl : configServer.dev.apiUrl  ,
    assetsUrl: (process.env.NODE_ENV === 'production') ? configServer.prod.assetsUrl : configServer.dev.assetsUrl  ,
    resetPasswordUrl: (process.env.NODE_ENV === 'production') ? configServer.prod.resetPasswordUrl : configServer.dev.resetPasswordUrl,
    contact :
    {
        web: 'https://www.roripartners.com',
        mail: 'contact@roripartners.com',
        phone: 'tel:0033972567092',
    },
    languages :
    {
        'en':'English',
        'fr':'Français',
        //'ja':'Japanses',
    }
}