export function authHeader() {
    // return authorization header with jwt token
    //let user = JSON.parse(localStorage.getItem('user'));

    
    const token = JSON.parse(localStorage.getItem('token') || '{}');
    

    //console.log("token",token)
    if(token) {
        return { 'Authorization': 'Bearer ' + token };
    } else {
        return {};
    }
}