
import {
  IonContent,
  IonPage,
  IonIcon,
  IonButton,
  loadingController,
  alertController,
  IonSlides,
  IonSlide,
  modalController
} from '@ionic/vue';

import { useRouter } from 'vue-router';

import { defineComponent } from 'vue';
import { mapState, mapActions } from 'vuex'

import { arrowBackOutline} from 'ionicons/icons';  

import  LangBtn from '../components/LangBtn.vue';
//import  DashboardBtn from '../components/DashboardBtn.vue';

import  DataKey from '../components/DataKey.vue';
//import  DocumentList from '../components/DocumentList.vue';
import DocumentModal from '../components/DocumentModal.vue';
import GalleryModal from '../components/GalleryModal.vue';

import {
  LMap,
  //LIcon,
  LTileLayer,
  //LControlZoom,
  //LControlAttribution,
  //LMarker,
  //LControlLayers,
  //LTooltip,
  //LPopup,
  //LPolyline,
  //LPolygon,
  //LRectangle,
  //LCircleMarker,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";


import config from '../_services/config';
import { Plugins } from '@capacitor/core';


const { Browser } = Plugins;



export default defineComponent({
  name: 'RealEstateAssetDetail',
  components: {
    IonContent, 
    IonPage,
    IonIcon,
    IonSlides, IonSlide,
    IonButton,
    
    LangBtn,
    //DashboardBtn,
    
    LMap,
    LTileLayer,
    
    DataKey,
    //DocumentList,
    

  },
  props:
  {
    id:{
      type: [ Number, String ],
      required: true,
    }
  },
  data()
  {
    const slideOpts = {
      initialSlide: 0,
      speed: 400,
      //slidesPerView: 'auto',
      slidesPerView: 1,
      spaceBetween: 30,
      centeredSlides: true,
      
    };

    const loading: HTMLIonLoadingElement|any = null;
    return { loading, mapZoom : 15, slideOpts}
  },
  setup() {
    const router = useRouter();
    
    return { router, arrowBackOutline };
  },
  computed: {
    ...mapState<any>({
        realEstateAssets: state =>  state.authentication.realEstateAssets,
        asset: function(){
          //console.log("asset list", this.realEstateAssets);
          const a = this.realEstateAssets.find( (r) => {return r.id == this.id});
          if(!a)
          {
            console.log("realEstateAsset not found", this.id);
            //todo return to dashboard or login
          }

          console.log("asset is", a);
          return a;
        },
        mapCenter: function(){
          //console.log('mapCenter',[this.asset.gps_coordinates.latitude, this.asset.gps_coordinates.longitude]);
          return [this.asset.gps_coordinates.latitude, this.asset.gps_coordinates.longitude]
        },
        currentAssetPreview: function(){
          if(this.asset?.preview)
          {
            if(this.asset.preview.generated_conversions?.thumb512)
              return config.assetsUrl+this.asset.preview.generated_conversions?.thumb512;

            return config.assetsUrl+this.asset.preview.public_url;
          }
          return null;
        },
    })
  },
  async created () {

      await this.presentLoading();
      
      //this.getUser().finally(() => this.dismissLoading());  
      Promise.all([this.getRealEstateAssets()]).then(values => {
        //console.log(values);
        this.dismissLoading();  
      }).catch(reason => {
        //console.log(reason);
        this.dismissLoading();  
      });
  },
  methods: {
    ...mapActions('authentication', {
        getRealEstateAssets: 'getRealEstateAssets',
    }),
    async presentLoading() {
      //console.log("Dashboard presentLoading", this.loading);
      if(this.loading !== null)
        return;

      this.loading = await loadingController
        .create({
          cssClass: 'app-loading',
          message: this.$t('loading.pleaseWait'),
          //duration: this.timeout,
        });

      //console.log("Dashboard presentLoading 2", this.loading);
      await this.loading.present();

    },
    dismissLoading()
    {
      //console.log("Dashboard dismiss", this.loading);
      if(this.loading !== null)
      {
        //console.log("Dashboard dismiss 2");
        this.loading.dismiss();
        this.loading = null;
      }
    },
    hasHistory () { return window.history.length > 2 },
    mapReady()
    {
      console.log("leaflet map is ready");
      (this.$refs.map as typeof LMap).leafletObject.attributionControl.remove();
      (this.$refs.map as typeof LMap).leafletObject.zoomControl.remove();
      //this.$nextTick( () =>
      //(this.$refs.map as typeof LMap).leafletObject.invalidateSize());
      setTimeout(()=> 
      {
        
         (this.$refs.map as typeof LMap).leafletObject.invalidateSize();
         //console.log((this.$refs.map as typeof LMap).leafletObject.zoomControl);
         //(this.$refs.map as typeof LMap).leafletObject.attributionControl.remove();
         //(this.$refs.map as typeof LMap).leafletObject.zoomControl.remove();
         //this.fitToAll();
      }, 500);
    },
    async openDocumentModal() {
      const modal = await modalController
        .create({
          component: DocumentModal,
          cssClass: 'my-custom-class',
          componentProps: {
            documents: this.asset.assets,
          },
        })
      return modal.present();
    },
    async openGalleryModal(index: number) {
      const modal = await modalController
        .create({
          component: GalleryModal,
          cssClass: 'app-modal-gallery',
          componentProps: {
            startIndex: index,
            title: this.asset.name,
            pictures: this.asset.pictures,
          },
        })
      return modal.present();
    },
  },
  watch: {
    
  },
  ionViewDidEnter() {
    console.log('Home page did enter');
    //document.title='Rori Partners - Dashboard';
    setTimeout( () => { (this.$refs.mySwiper as any).$el.update()}, 500 );
  },

  
   
});
