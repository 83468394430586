<template>
<div>
  <div v-if="data.length == 0" class="no-data-list">
    <h3>{{$t('components.dataKey.empty')}}</h3>
  </div>
  <ion-list v-else>
      <!-- <data-key-item  v-for="d in data" :key="d" :label="d.name[$i18n.locale]" :value="d.value[$i18n.locale]" ></data-key-item> -->
      <!-- <data-key-item  v-for="d in data" :key="d" :type="d.key" :label="d.name[$i18n.locale]" :value=" d.type=='number' ? $filters.formatNumber(Number(d.raw_value),'0,0[.]00'): d.value[$i18n.locale]" ></data-key-item> -->
      <data-key-item  v-for="d in data" :key="d" :type="d.key" :label="d.name[$i18n.locale]" :value=" d.type=='number' ? d.raw_value: d.value[$i18n.locale]" ></data-key-item>
  </ion-list>
</div>
  
</template>

<script lang="ts">
import {  IonList } from '@ionic/vue';
import { defineComponent } from 'vue';
import DataKeyItem from './DataKeyItem.vue';


export default defineComponent({
  name: 'data-key',
  components: {
    IonList,
    
    DataKeyItem
    
  },
  props: {
    data: {type:Array}
  },
  setup() {  
    return { keys :5 };
  },
  methods: {
    
  },
});
</script>

<style scoped>

.no-data-list
{
  margin: auto;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  color: darkgray;

}

</style>