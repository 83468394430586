<template>
  <ion-page>
    <ion-content :fullscreen="true">

        <div id="container" class="container">
          <div class="cmd-btn">
            <div class="cmd-btn-fixed">
              <lang-btn class="lang-btn"></lang-btn>
              <ion-button class="back-btn" fill="clear"  @click="hasHistory() ? router.back() :router.push('/') ">
                <ion-icon :icon="arrowBackOutline"></ion-icon>
              </ion-button>
            </div>
          </div>
          <ion-button v-if="true" class="map-btn" expand="full" shape="round" :router-link="{'name':'RealEstateAssetMap', 'params':{ 'id' :parseInt(asset.id)}}" >{{$t('realEstateAsset.mapBtn')}}</ion-button>
          <l-map id="map" ref="map" :center="mapCenter" v-model:zoom="mapZoom" :zoomAnimation="true" :fadeAnimation="true" :markerZoomAnimation="true" :inertia="true" @ready="mapReady()">
            <l-tile-layer url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png"></l-tile-layer>
            
          </l-map>

          <div class="panel-container">
            <h1 class="title">{{asset.name}}</h1>
            <h4 class="adress">{{asset.adress.line1 +(asset.adress.line2?' '+asset.adress.line2:'') +' - '+ asset.adress.postal_code + ' ' + asset.adress.city}}</h4>
            <ion-slides v-if="asset.pictures.length >0" :key="asset.pictures" ref="mySwiper" class="slider" pager="true" :options="slideOpts">
              <ion-slide class="slide" v-for="(p, index) in asset.pictures" :key="p" @click="openGalleryModal(index)" :style="{'background-image' :  'url(' + $filters.getMediaPreview(p)+')','background-position': 'center','background-repeat': 'no-repeat','background-size': 'cover'}">
                <!-- <img :src="$filters.getMediaPreview(p)"/> -->
              </ion-slide>
            </ion-slides>
            <p class="description">{{asset.description[$i18n.locale]}}</p>
            
          </div>
          
          <div class="data-panel">
            <data-key class="data-key" :data="asset.metadatas"></data-key>
          </div>
          <ion-button v-if="asset.assets.length >0" class="doc-btn" fill="clear" @click="openDocumentModal">{{$t('components.documentModal.sectionTitle')}}
            <div slot="end" class="doc-btn-icon"></div>
          </ion-button>
        </div>
      
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonPage,
  IonIcon,
  IonButton,
  loadingController,
  alertController,
  IonSlides,
  IonSlide,
  modalController
} from '@ionic/vue';

import { useRouter } from 'vue-router';

import { defineComponent } from 'vue';
import { mapState, mapActions } from 'vuex'

import { arrowBackOutline} from 'ionicons/icons';  

import  LangBtn from '../components/LangBtn.vue';
//import  DashboardBtn from '../components/DashboardBtn.vue';

import  DataKey from '../components/DataKey.vue';
//import  DocumentList from '../components/DocumentList.vue';
import DocumentModal from '../components/DocumentModal.vue';
import GalleryModal from '../components/GalleryModal.vue';

import {
  LMap,
  //LIcon,
  LTileLayer,
  //LControlZoom,
  //LControlAttribution,
  //LMarker,
  //LControlLayers,
  //LTooltip,
  //LPopup,
  //LPolyline,
  //LPolygon,
  //LRectangle,
  //LCircleMarker,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";


import config from '../_services/config';
import { Plugins } from '@capacitor/core';


const { Browser } = Plugins;



export default defineComponent({
  name: 'RealEstateAssetDetail',
  components: {
    IonContent, 
    IonPage,
    IonIcon,
    IonSlides, IonSlide,
    IonButton,
    
    LangBtn,
    //DashboardBtn,
    
    LMap,
    LTileLayer,
    
    DataKey,
    //DocumentList,
    

  },
  props:
  {
    id:{
      type: [ Number, String ],
      required: true,
    }
  },
  data()
  {
    const slideOpts = {
      initialSlide: 0,
      speed: 400,
      //slidesPerView: 'auto',
      slidesPerView: 1,
      spaceBetween: 30,
      centeredSlides: true,
      
    };

    const loading: HTMLIonLoadingElement|any = null;
    return { loading, mapZoom : 15, slideOpts}
  },
  setup() {
    const router = useRouter();
    
    return { router, arrowBackOutline };
  },
  computed: {
    ...mapState<any>({
        realEstateAssets: state =>  state.authentication.realEstateAssets,
        asset: function(){
          //console.log("asset list", this.realEstateAssets);
          const a = this.realEstateAssets.find( (r) => {return r.id == this.id});
          if(!a)
          {
            console.log("realEstateAsset not found", this.id);
            //todo return to dashboard or login
          }

          console.log("asset is", a);
          return a;
        },
        mapCenter: function(){
          //console.log('mapCenter',[this.asset.gps_coordinates.latitude, this.asset.gps_coordinates.longitude]);
          return [this.asset.gps_coordinates.latitude, this.asset.gps_coordinates.longitude]
        },
        currentAssetPreview: function(){
          if(this.asset?.preview)
          {
            if(this.asset.preview.generated_conversions?.thumb512)
              return config.assetsUrl+this.asset.preview.generated_conversions?.thumb512;

            return config.assetsUrl+this.asset.preview.public_url;
          }
          return null;
        },
    })
  },
  async created () {

      await this.presentLoading();
      
      //this.getUser().finally(() => this.dismissLoading());  
      Promise.all([this.getRealEstateAssets()]).then(values => {
        //console.log(values);
        this.dismissLoading();  
      }).catch(reason => {
        //console.log(reason);
        this.dismissLoading();  
      });
  },
  methods: {
    ...mapActions('authentication', {
        getRealEstateAssets: 'getRealEstateAssets',
    }),
    async presentLoading() {
      //console.log("Dashboard presentLoading", this.loading);
      if(this.loading !== null)
        return;

      this.loading = await loadingController
        .create({
          cssClass: 'app-loading',
          message: this.$t('loading.pleaseWait'),
          //duration: this.timeout,
        });

      //console.log("Dashboard presentLoading 2", this.loading);
      await this.loading.present();

    },
    dismissLoading()
    {
      //console.log("Dashboard dismiss", this.loading);
      if(this.loading !== null)
      {
        //console.log("Dashboard dismiss 2");
        this.loading.dismiss();
        this.loading = null;
      }
    },
    hasHistory () { return window.history.length > 2 },
    mapReady()
    {
      console.log("leaflet map is ready");
      (this.$refs.map as typeof LMap).leafletObject.attributionControl.remove();
      (this.$refs.map as typeof LMap).leafletObject.zoomControl.remove();
      //this.$nextTick( () =>
      //(this.$refs.map as typeof LMap).leafletObject.invalidateSize());
      setTimeout(()=> 
      {
        
         (this.$refs.map as typeof LMap).leafletObject.invalidateSize();
         //console.log((this.$refs.map as typeof LMap).leafletObject.zoomControl);
         //(this.$refs.map as typeof LMap).leafletObject.attributionControl.remove();
         //(this.$refs.map as typeof LMap).leafletObject.zoomControl.remove();
         //this.fitToAll();
      }, 500);
    },
    async openDocumentModal() {
      const modal = await modalController
        .create({
          component: DocumentModal,
          cssClass: 'my-custom-class',
          componentProps: {
            documents: this.asset.assets,
          },
        })
      return modal.present();
    },
    async openGalleryModal(index: number) {
      const modal = await modalController
        .create({
          component: GalleryModal,
          cssClass: 'app-modal-gallery',
          componentProps: {
            startIndex: index,
            title: this.asset.name,
            pictures: this.asset.pictures,
          },
        })
      return modal.present();
    },
  },
  watch: {
    
  },
  ionViewDidEnter() {
    console.log('Home page did enter');
    //document.title='Rori Partners - Dashboard';
    setTimeout( () => { (this.$refs.mySwiper as any).$el.update()}, 500 );
  },

  
   
});
</script>


<style scoped>

ion-content
{
  --background : rgb(248, 248, 248);
  
}

#container {
  display: flex;
  flex-direction: column;
  background-color: white;
  color:gray;
  
  position: relative;
  margin: auto;
  max-width: var(--container-responsive-max-width);

  min-height: 100%;
  padding-bottom: 50px;
  
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#map
{
  
  width: 100%;
  height: 200px !important;
  flex: none;
  pointer-events: none;
}

.adress
{
  color:gray;
  margin-top:0px;
  font-size: 1.1em;
}
.description
{
  text-align: justify;
}

.panel-container
{
  margin: 20px;
  display: flex;
  flex-direction: column;
}

.cmd-btn
{
  position: absolute;
  pointer-events: auto;
  top: 0px;
  left: 0;
  right: 0;
  
  z-index: 1000;
}

.map-btn
{
  position: absolute;
  pointer-events: auto;
  top: 80px;
  left: 50px;
  right: 50px;

  height: 40px;
  border: 1px solid var(--ion-color-primary);
  border-radius: 20px;
  --border-radius:20px;
  --background: rgba(255,255,255,.5);
  color: var(--ion-color-primary);
  font-size: 0.8em;


  z-index: 1000;
}


.cmd-btn-fixed
{
  position: fixed;
  width: 100%;
  max-width: var(--container-responsive-max-width);
  height: 60px;

  
}


.lang-btn
{
  position: absolute;
  width: 60px;
  height: 60px;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  text-align: center;
}

.dashboard-btn
{
  position: absolute;
  width: 40px;
  height: 40px;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  text-align: center;
}

.back-btn
{
  font-size: 20px;
}

.doc-btn
{
  margin: 20px auto;
  height: 40px;
  font-size: 1.2em;
}
.doc-btn-icon
{
  margin: auto 10px;
  width: 25px;
  height: 25px;
  background: url( '../../public/assets/img/PICT_Plus_R.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

}
.slider
{
  width: 100%;
  max-width: 400px;
  height: 250px;
}
.slide
{
  
  background-color:lightgray;
}

@media(min-width:540px)
{
  .cmd-btn
  {
    height: 60px;
  }

  .lang-btn
  {
    width: 60px;
    height: 60px;
  }

  .dashboard-btn
  {
    width: 40px;
    height: 40px;
  }
}

</style>