<template>
  <ion-slides class="slides" :key="items" ref="mySwiper" pager="true" :options="slideOpts" @ionSlideDidChange="slideDidChange">
    <template v-for="(i,index) in items" :key="i">
      <lightbox-item v-if="i.type=='photo'" class="slide"   :active="currentIndex == index" :url="i.url"></lightbox-item>
      <lightbox-item-360 v-if="i.type=='360'" class="slide" :active="currentIndex == index" :url="i.url" v-on:prev="prev()" v-on:next="next()" ></lightbox-item-360>
    </template>
  </ion-slides>

</template>

<script lang="ts">
import {  
  IonSlides,
  } from '@ionic/vue';

import { defineComponent } from 'vue';
import LightboxItem from './LightboxItem.vue';
import LightboxItem360 from './LightboxItem360.vue';


export default defineComponent({
  name: 'lightbox',
  components: {
    IonSlides,
    LightboxItem,
    LightboxItem360,

  },
  props: {
    startIndex: Number,
    items: {type:Array}
  },
  
  data()
  {
    const currentIndex = this.startIndex;
    const slideOpts = {
      initialSlide: this.startIndex,
      zoom: true,
      speed: 400,
      slidesPerView: 1,
      spaceBetween: 30,
      centeredSlides: true,
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
      
    };

    console.log('slideOpts', slideOpts);

    return {slideOpts,currentIndex,}
  },
  setup() {  
    

    return { 
      //slideOpts,
      //messages : 5
     };
  },
  methods: {
   prev()
   {
      (this.$refs.mySwiper as any).$el.slidePrev();
   },
   next()
   {
      (this.$refs.mySwiper as any).$el.slideNext();
   },
   async slideDidChange()
    {
      //console.log("slideDidChange 1");
      //const index = await (this.$refs.slides as typeof IonSlides ).getActiveIndex();
      
      //console.log("slideDidChange 2", (this.$refs.mySwiper as any).$el.getActiveIndex());
      
      (this.$refs.mySwiper as any).$el.getActiveIndex().then( (i) => {
        //console.log("slideDidChange 3",i);
        
        this.currentIndex = i;
        
        
      });
      
    },
  },
  mounted()
  {
    //mySwiper.reInit(true)
    console.log("mounted lightbox", (this.$refs.mySwiper as any).$el);
    
    //this.$nextTick( () => { (this.$refs.mySwiper as any).$el.update()});
    setTimeout( () => { (this.$refs.mySwiper as any).$el.update()}, 500 );
    //this.$nextTick( () => { (this.$refs.mySwiper as any).$el.pagination.update()});
    //this.$nextTick().then(() =>this.$nextTick(  () => { (this.$refs.mySwiper as any).$el.update()}));
  },
  
});
</script>

<style scoped>

ion-slides
{
  --bullet-background	:black;
}
ion-slide
{
  width: unset;
}

.slides
{
  width: 100%;
  height: 100%;
}

.slide
{
  width: 100%;
  background-color: white;
}

.object-fit {
    width: 100%;
    background-color:white;
 }

.no-messages-card
{
  width: 250px;
  height:150px;
  margin: 5px 10px;
  display: flex;
}

.no-messages-text
{
  margin: auto;
  padding: 10px;
  width: 100%;
  
  font-size: 2em;
}

.swiper-pagination-bullet {
  background: white !important;
  opacity: 0.2 !important;
}

.swiper-pagination-bullet-active {
  opacity: 0.9 !important;
  background: white !important;
}
</style>