
import { IonContent, IonGrid, IonPage, IonButton, IonList, IonInput } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonGrid,
    IonList,
    IonPage,
    //IonItem,
    //IonLabel,
    IonButton,
    IonInput
  },
  setup() {
      const router = useRouter();
      return { router };
    }
});
