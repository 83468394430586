<template>
  <div>
    <ion-list>
        <document-list-item  v-for="m in documents" :key="m.id" :filename="m.name" :date="m.updated_at" :src="m.public_url"></document-list-item>
    </ion-list>
  </div>
</template>

<script lang="ts">
import {  IonList } from '@ionic/vue';
import { defineComponent } from 'vue';
import DocumentListItem from './DocumentListItem.vue';



export default defineComponent({
  name: 'document-list',
  props:
  {
    documents:{type:Array}
  },
  components: {
    IonList,
    
    DocumentListItem
    
  },
  setup() {  
    
    return { 
      
     };
  },
  methods: {
    
  },
});
</script>

<style scoped>

ion-list
{
background-color : rgba(0, 0, 0, 0);
}
.scroll
{
  overflow: auto;
}


</style>