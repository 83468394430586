
//import config from '@/_services/config';
import {  IonButton } from '@ionic/vue';
//import { key } from 'ionicons/icons';
import { defineComponent } from 'vue';
//import { useRouter } from 'vue-router';
import { arrowBackOutline} from 'ionicons/icons';  


export default defineComponent({
  name: 'dashboard-btn',
  components: {
    IonButton,
  },
  setup() {  
    return { arrowBackOutline };
  },
  computed:
  {
    
  },
  methods: {
    
  }
});
