<template>
    <ion-slide class="slide">
      <img class="object-fit" :src="url">
    </ion-slide>
</template>

<script lang="ts">
import {  
  IonSlide,
  } from '@ionic/vue';

import { defineComponent } from 'vue';


export default defineComponent({
  name: 'lightbox-item',
  components: {
     IonSlide,

  },
  props: {
    active : {type:Boolean, default:true},
    type: {type:String},
    url: {type:String},
    title: {type:String},
    message: {type:String}
  },
});






</script>

<style scoped>


ion-slide
{
  width: unset;
}


.object-fit {
    width: 100%;
    background-color:white;
 }

</style>