
import {  IonList } from '@ionic/vue';
import { defineComponent } from 'vue';
import DataKeyItem from './DataKeyItem.vue';


export default defineComponent({
  name: 'data-key',
  components: {
    IonList,
    
    DataKeyItem
    
  },
  props: {
    data: {type:Array}
  },
  setup() {  
    return { keys :5 };
  },
  methods: {
    
  },
});
