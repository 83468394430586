<template>
  <ion-page>
    
    <ion-content :fullscreen="true">
      
      <div id="container">
          <div class="cmd-btn">
            <lang-btn class="lang-btn"></lang-btn>
            <ion-button class="back-btn" fill="clear"  @click="hasHistory() ? router.back() :router.push('/') ">
                <ion-icon :icon="arrowBackOutline"></ion-icon>
              </ion-button>
          </div>
        
          <l-map id="map" class="map" ref="map" :zoomAnimation="true" :fadeAnimation="true" :markerZoomAnimation="true" :inertia="true" @ready="mapReady()">
            <div ref="mapBoundsContainer" class="map-bounds-container">
              <div ref="mapBounds" class="map-bounds"></div>
            </div>
            <l-tile-layer url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png"></l-tile-layer>
            <l-marker :key="asset" :lat-lng="mapCenter">
              <l-icon :icon-url="markerIconOnUrl" :icon-size="[15, 15]" />
            </l-marker>
            <template v-for="l in layers" :key="l">
              <l-feature-group :ref="(el) => {layerRefMap.set(l,el);}" :visible="l.state" >
                <leaflet-overpass :ref="l.name" :noInitialRequest="!l.state" :retryOnError="true" :bounds="mapBox" :query="l.query" @on-marker-click="showInfoPane" @on-loading="(b) =>{l.isLoading = b}" :selectedNode="currentMarker?.id" :markerIconOn="createImageCircle(100,10,l.iconColor,l.iconColor, '#FFFFFF00')" :markerIconOff="createImageCircle(100,5,'#A7A7A7FF',l.iconColor, '#FFFFFF00')" ></leaflet-overpass>
              </l-feature-group>
            </template>
          </l-map>

          <div class="panel-container">
              <div class="panel">
                <ion-button class="map-btn" expand="full" shape="round" @click="layerPane.present({animate: true});" >{{$t('realEstateAsset.map.configureLayer')}}</ion-button>
              </div>
          </div>

          <div ref="layerPane" class="cupertino-pane">
              <h1>{{$t('realEstateAsset.map.configureLayer')}}</h1>
              <ion-list>
                <template v-for="(l,k) in layers" :key="k">
                  <ion-item class="layerPaneItem">
                    <ion-label>{{$t(l.name)}}</ion-label>
                    <ion-toggle slot="start" v-bind:style="{ '--background-checked': l.toggleBgColor, '--handle-background-checked': l.toggleHandleColor}" :checked="l.state" @ionChange="setLayer(l,$event.target.value)"></ion-toggle>
                    <ion-spinner slot="end" v-if="l.isLoading" name="lines-small" ></ion-spinner>
                  </ion-item>
                </template>
              </ion-list> 
          </div>

          <div ref="infoPane" class="cupertino-pane">
            <leaflet-poi-detail :data="currentMarker" :debug="false"></leaflet-poi-detail>
          </div>

      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonPage, IonButton, loadingController, IonIcon, IonLabel, IonItem, IonList, IonSpinner} from '@ionic/vue';

import { useRouter } from 'vue-router';

import { defineComponent, getTransitionRawChildren, ref } from 'vue';

import { mapState, mapActions } from 'vuex'

import { arrowBackOutline} from 'ionicons/icons';  


import  LangBtn from '../components/LangBtn.vue';

import  LeafletOverpass from '../components/leaflet/LeafletOverpass.vue';
import  LeafletPoiDetail from '../components/leaflet/LeafletPoiDetail.vue';

import { CupertinoPane, CupertinoSettings } from 'cupertino-pane';

import {
  LMap,
  LIcon,
  LTileLayer,
  //LControlZoom,
  //LControlAttribution,
  LFeatureGroup,
  LMarker,
  //LControlLayers,
  //LTooltip,
  //LPopup,
  //LPolyline,
  //LPolygon,
  //LRectangle,
  //LCircleMarker,
} from "@vue-leaflet/vue-leaflet";

//import LMarkerClusterGroup from '../components/map/LMarkerClusterGroup.vue';


import "leaflet/dist/leaflet.css";
import config from '@/_services/config';
//import { bounds } from 'leaflet';
//import { Map } from 'leaflet';



export default defineComponent({
  name: 'RealEstateAssetMap',
  components: {
    
    IonContent,
    //IonRefresher, IonRefresherContent,
    IonPage,
    IonButton,
    IonIcon,
    IonLabel, IonItem, IonList, IonSpinner, 
    //IonNote,
    
    LangBtn,
    

    LMap,
    LFeatureGroup,
    LMarker,
    LIcon,
    //LCircleMarker,
    LTileLayer,
    //LControlZoom,
    //LControlAttribution,

    //LMarkerClusterGroup,
    
    
    LeafletOverpass,
    LeafletPoiDetail
  },
  props:
  {
    id:{
      type: [ Number, String ] ,
      required: true,
    }
  },
  data() {
    
    const layerPane: any = null;
    const infoPane: any = null;
    
    const currentMarker: any = null;
        

    const isOpenRef = ref(false);
    const setOpen = (state: boolean) => { isOpenRef.value = state;}
    
    const loading: HTMLIonLoadingElement|any = null;
    const state = true;
    const markerIconOnUrl = 'assets/img/MarkerMap_ON_R2.png';
    
    const layers = 
    {
      restaurant:
      {
        name : 'realEstateAsset.map.layers.restaurants',
        icon: 'restaurant',
        ref: null,
        state: true,
        isLoading: false,
        iconColor: '#3880ff',
        toggleBgColor: '#3880ff',
        toggleHandleColor: '#3171e0',
        query: '(node({{bbox}})[amenity~"restaurant|fast_food"];);out qt;'
      },
      transport:
      {
        name : 'realEstateAsset.map.layers.transports',
        icon: 'Transport',
        ref: null,
        state: false,
        isLoading: false,
        iconColor: '#07117d',
        toggleBgColor: '#07117d',
        toggleHandleColor: '#060f6e',
        color: 'secondary',
        query: '(node({{bbox}})[railway~"station"];node({{bbox}})[highway~"bus_stop"];node({{bbox}})[amenity~"parking|motorcycle_parking|bicycle_parking|bicycle_rental"];);out qt;'
      },
      sante:
      {
        name : 'realEstateAsset.map.layers.sante',
        icon: 'Sante',
        ref: null,
        state: false,
        isLoading: false,
        iconColor: '#2dd36f',
        toggleBgColor: '#2dd36f',
        toggleHandleColor: '#28ba62',
        color: 'secondary',
        query: '(node({{bbox}})[amenity~"doctors|veterinary|pharmacy|clinic|dentist|hospital"];);out qt;'
      },
      bank:
      {
        name : 'realEstateAsset.map.layers.banques',
        icon: 'Banque',
        ref: null,
        state: false,
        isLoading: false,
        iconColor: '#ffc409',
        toggleBgColor: '#ffc409',
        toggleHandleColor: '#e0ac08',
        color: 'secondary',
        query: '(node({{bbox}})[amenity~"bank|bureau_de_change"];);out qt;'
      },
      commerces:
      {
        name : 'realEstateAsset.map.layers.commerces',
        icon: 'Commerces',
        ref: null,
        state: false,
        isLoading: false,
        iconColor: '#eb445a',
        toggleBgColor: '#eb445a',
        toggleHandleColor: '#cf3c4f',
        color: 'secondary',
        query: '(node({{bbox}})[shop~"supermarket|convenience|bakery"];);out qt;'
      },
      school:
      {
        name : 'realEstateAsset.map.layers.education',
        icon: 'Education',
        ref: null,
        state: false,
        isLoading: false,
        iconColor: '#586a8d',
        toggleBgColor: '#586a8d',
        toggleHandleColor: '#4d5d7c',
        color: 'secondary',
        query: '(node({{bbox}})[amenity~"college|library|school|university|kindergarten"];);out qt;'
      },
      loisir:
      {
        name : 'realEstateAsset.map.layers.loisirs',
        icon: 'Loisir',
        ref: null,
        state: false,
        isLoading: false,
        iconColor: '#92949c',
        toggleBgColor: '#92949c',
        toggleHandleColor: '#808289',
        color: 'secondary',
        query: '(node({{bbox}})[amenity~"arts_centre|cinema|community_centre|fountain|nightclub|theatre"];);out qt;'
      },
      sport:
      {
        name : 'realEstateAsset.map.layers.sports',
        icon: 'Sport',
        ref: null,
        state: false,
        isLoading: false,
        iconColor: '#f4f5f8',
        toggleBgColor: '#f4f5f8',
        toggleHandleColor: '#d7d8da',
        color: 'secondary',
        query: '(node({{bbox}})[leisure~"sports_centre|stadium|pitch|fitness_centre|golf_course"];);out qt;'
      },
      hotel:
      {
        name : 'realEstateAsset.map.layers.hotels',
        icon: 'Hotel',
        ref: null,
        state: false,
        isLoading: false,
        iconColor: '#f2ff38',
        toggleBgColor: '#f2ff38',
        toggleHandleColor: '#d5e031',
        color: 'secondary',
        query: '(node({{bbox}})[tourism~"hotel"];);out qt;'
      },
      entreprise:
      {
        name : 'realEstateAsset.map.layers.entreprises',
        icon: 'Entreprise',
        ref: null,
        state: false,
        isLoading: false,
        iconColor: '#ff38ac',
        toggleBgColor: '#ff38ac',
        toggleHandleColor: '#e03197',
        color: 'secondary',
        query: '(node({{bbox}})[office~"company"];);out qt;'
      },
    };
    const layerRefMap = new Map();
    //const currentTagLocalisation = require(`../locales/osm/tags/${this.$i18n.locale}.json`);
    return {
      markerIconOnUrl,
      loading,
      
      state,
      isOpenRef, setOpen,

      layerPane,
      infoPane,

      layers,
      layerRefMap,

      currentMarker

    };
  },
  setup() {  
    
    const router = useRouter();
    
    return { 
      router,
      //layers,
      arrowBackOutline 
    };
  },
  mounted()
  {
    const layerPaneSettings: CupertinoSettings = {
      backdrop: true,
      fitHeight:true,
      onBackdropTap: () => this.layerPane.destroy({animate:true}),
    };

    const infoPaneSettings: CupertinoSettings = {
      backdrop: true,
      fitHeight:true,
      maxFitHeight:300,
      onBackdropTap: () => {this.infoPane.destroy({animate:true});},
      onDidDismiss: () => {this.currentMarker = null;},
    };


    this.layerPane = new CupertinoPane( this.$refs.layerPane as HTMLElement, layerPaneSettings );

    this.infoPane = new CupertinoPane( this.$refs.infoPane as HTMLElement, infoPaneSettings );
    
    //this.cupertinoPane.present({animate: true});
  },
  watch:
  {
    layers:
    {
      deep:true,
      handler()
      {
        //console.log('The list of layers has changed!');
      }
    }
  },
  computed: {
    ...mapState<any>({
        realEstateAssets: state =>  state.authentication.realEstateAssets,
        asset: function(){
          //console.log("asset list", this.realEstateAssets);
          const a = this.realEstateAssets.find( (r) => {return r.id == this.id});
          if(!a)
          {
            console.log("realEstateAsset not found", this.id);
            //todo return to dashboard or login
          }

          //console.log("asset is", a);
          return a;
        },
        mapCenter: function(){
          //console.log('mapCenter',[this.asset.gps_coordinates.latitude, this.asset.gps_coordinates.longitude]);
          return [this.asset.gps_coordinates.latitude, this.asset.gps_coordinates.longitude];
        },
        mapBox: function(){
          //console.log('mapCenter',[this.asset.gps_coordinates.latitude, this.asset.gps_coordinates.longitude]);
          return [[this.asset.gps_box.south, this.asset.gps_box.west],[ this.asset.gps_box.north, this.asset.gps_box.east]];
        },
        currentMarkerTitle: function()
        {
          //if(this.currentMarker)
          {
            return this.currentMarker?.tags?.name;
          }
        },
        currentMarkerSubtitle: function()
        {
          //if(this.currentMarker)
          {
            //return this.getTagTranslation(this.currentMarker?.tags?.amenity);
          }
          return null;
        },
        currentLanguageTranslation()
        {
          return require(`../locales/osm/tags/${this.$i18n.locale}.json`);
          //return this.$i18n.locale;
        },
    })
  },
  methods: {
    ...mapActions('authentication', {
        getRealEstateAssets: 'getRealEstateAssets',
    }),
    async presentLoading() {
      
      if(this.loading !== null)
        return;

      this.loading = await loadingController
        .create({
          cssClass: 'app-loading',
          message: this.$t('loading.pleaseWait'),
          //duration: this.timeout,
        });

      await this.loading.present();

    },
    dismissLoading()
    {
      if(this.loading !== null)
      {
        this.loading.dismiss();
        this.loading = null;
      }
    },
    hasHistory () { return window.history.length > 2 },
    mapReady()
    {
      //console.log("leaflet map is ready");
      (this.$refs.map as typeof LMap).leafletObject.attributionControl.remove();
      (this.$refs.map as typeof LMap).leafletObject.zoomControl.remove();
      //this.$nextTick( () =>
      //(this.$refs.map as typeof LMap).leafletObject.invalidateSize());
      setTimeout(()=> 
      {
        
         (this.$refs.map as typeof LMap).leafletObject.invalidateSize();
         //console.log((this.$refs.map as typeof LMap).leafletObject.zoomControl);
         //(this.$refs.map as typeof LMap).leafletObject.attributionControl.remove();
         //(this.$refs.map as typeof LMap).leafletObject.zoomControl.remove();
         //this.$nextTick( () =>this.fitToAll());
         //this.fitToAll();
         
         
        //const mapBounds = (this.$refs.mapBounds as any).getBoundingClientRect();
        //console.log(this.mapBox);
        //(this.$refs.map as typeof LMap).leafletObject.fitBounds(this.mapBox,{padding: [mapBounds.left, mapBounds.top]});
        this.$nextTick( () =>
        {
          const m = (this.$refs.map as typeof LMap).leafletObject;
          
          m.setMinZoom( m.getBoundsZoom(this.mapBox ) );
          m.fitBounds(this.mapBox);
          m.setMaxBounds(this.mapBox);
        });

      }, 500);
      
    },
    setLayer(l, s)
    {
      //console.log(this.$refs);
      //console.log(this.layerRefMap);
      
      //console.log(l,s);
      //l.state = s == 'on';
      l.state = !l.state;
      //this.$set(l,'state',l.state);
      //const fg = ref('l-feature-group_' +l.name );
      //(this.layerRefMap.get(l) as any).setVisible(l.state);
      
      //(l.ref as any).setVisible(l.state);
      //(fg as any).setVisible(l.state);
      
      if(l.state)
      {
        (this.$refs[l.name] as any).sendRequest();
      }
    },
    showInfoPane(m: any)
    {
      //console.log("show info pane",m);
      this.currentMarker = m;
      this.infoPane.present({animate: true});
    },
    createImageCircle(size: number,borderWidth: number ,colorBorder: string, colorInner: string, colorBg: string)
    {
      
      const canvas=document.createElement('canvas');
      const context=canvas.getContext('2d');
      canvas.width=canvas.height=size;

      if(context === null)
        return;

      context.fillStyle=colorBg;
      context.fillRect(0,0,size,size);

      context.beginPath();
      context.arc((size/2),(size/2), (size/4), 0, 2 * Math.PI, false);
      context.fillStyle = colorInner;
      context.fill();

      context.lineWidth = borderWidth;
      context.strokeStyle = colorBorder;
      context.stroke();
      
      return canvas.toDataURL();
    },
    getTagTranslation(tag,v)
    {
      const loc = this.currentLanguageTranslation;
      //console.log(loc);
      const tv = `tag:${tag}=${v}`;
      const t = `tag:${tag}`;
      if(tv in loc)
      {
        return loc[tv].message;
      }
      
      if(t in loc)
      {
        return loc[t].message;
      }

      return tag;
    }
  },
  async ionViewDidEnter() {
    //console.log('Home page did enter');
    //document.title='Rori Partners - Map';

    await this.presentLoading();
    console.log('ionViewDidEnter enter'); 
    //(this.$refs.slides as any).$el.update();
    //this.syncSlide(0);
     
    Promise.all([this.getRealEstateAssets()]).finally(() => {
      
      this.dismissLoading(); 
      //console.log("reset slide");
      //this.$nextTick( () => { (this.$refs.slides as any).$el.update(); this.syncSlide();});
      this.$nextTick( () =>
        {
          const m = (this.$refs.map as typeof LMap).leafletObject;
          
          m.setMinZoom( m.getBoundsZoom(this.mapBox ) );
          m.fitBounds(this.mapBox);
          m.setMaxBounds(this.mapBox);
        });
    });
  }
  
});
</script>

<style scoped>
#container { 
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  min-height: 550px;
  display: flex;
  flex-direction: column;
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
  
}


.map
{
  
  width: 100%;
  flex-grow: 10;
  pointer-events: all;
  position: relative;
}
.map-bounds-container
{
  margin: auto;
  padding: 100px;
  height: 100%;
  min-height: 100px;
  min-width: 100px;
  max-width: var(--container-responsive-max-width);
  
}
.map-bounds
{
  width: 100%;
  height: 100%;
  z-index: 10000;
}


.cmd-btn
{
  position: absolute;
  pointer-events: auto;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 60px;
  z-index: 1000;
}

.lang-btn
{
  position: absolute;
  width: 60px;
  height: 60px;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  text-align: center;
}

.detail-btn
{
  display: block;
  margin: 20px auto;
  width: 60px;
  height: 60px;
  background: url( '../../public/assets/img/PICT_FlecheDroiteCercle_R.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.dashboard-btn
{
  position: absolute;
  width: 40px;
  height: 40px;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  text-align: center;
}

.back-btn
{
  font-size: 20px;
}

.panel-container
{
    position: absolute;
    bottom: 0px;
    width: 100%;
    z-index: 500;
    background: transparent;
}
.panel
{
  max-width: var(--container-responsive-max-width);
  
  margin: auto;
  overflow: auto;
  height: 100%;
  
}

.cupertino-pane
{
  margin: 20px;
}

.infoPaneItem
{
  --min-height: 20px;
}

@media(min-width:540px)
{
  .cmd-btn
  {
    height: 60px;
  }

  .lang-btn
  {
    width: 60px;
    height: 60px;
  }

  .dashboard-btn
  {
    width: 40px;
    height: 40px;
  }

  
}


</style>