import { createStore, createLogger} from 'vuex'

import { alert } from './alert.module';
import { authentication } from './authentication.module';
//import { users } from './users.module';

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
    modules: {
        alert,
        authentication
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
  })
