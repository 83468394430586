<template>
  <ion-item  class="btn" lines="none" @click="openDoc()">
    <ion-label  class="label-filename" slot="start">{{filename}}</ion-label>
    <ion-label class="label-date" slot="end">{{computeddate}}</ion-label>
  </ion-item>
</template>

<script lang="ts">
import {  IonItem,IonLabel } from '@ionic/vue';
import { defineComponent } from 'vue';

import moment from 'moment';
import config from '@/_services/config';
import { Browser } from '@capacitor/core';

export default defineComponent({
  name: 'document-list-item',
  components: {
    IonItem,
    IonLabel
  },
  props:
  {
    filename: String,
    date: String,
    src: String,
  },
  setup() {  
    
    return {  };
  },
  methods: {
    openDoc()
    {
      console.log("opening",this.src);
      Browser.open({ url: config.assetsUrl+this.src });
    }
  },
  computed: {
    computeddate(): string {
    
      this.$i18n.locale;
      return moment(this.date).format('L') ;
    }
  }
});
</script>




<style scoped>
ion-item
{
  --background :white;
  --border-radius : 25px;
  margin: 5px 0;
  
}

.btn
{
  display: inline-block;
  width: 100%;
  
  font-size: 0.9em;
  
  
  
}

.label-filename
{
  padding-left: 5px;
  font-weight: 700;
}
.label-date
{
  min-width: 100px;
  text-align: end;
  padding-right: 5px;
  font-style: italic;
}

.left
{
  float: left;
}

.right
{
  float: right;
}


</style>