
function applyFormat(data: any, combineFormat: string|null )
{
  switch(combineFormat)
  {
    case 'round': return Math.round(data);break;
    case 'floor': return Math.floor(data);break;
    case 'ceil': return Math.ceil(data);break;
  }
  return data;
}

function processCombine( data: any[], combineType: string, combineFormat: string|null)
{

    if(combineType == "count")
    {
        return data.length;
    }

    if(combineType == "min")
    {
        //return  Math.min(... data);
        return  applyFormat(Math.min(... data),combineFormat );
    }

    if(combineType == "max")
    {
        return Math.max(... data)
    }

    if(combineType == "min-max")
    {
        const min = applyFormat(Math.min(... data),combineFormat);
        const max = applyFormat(Math.max(... data),combineFormat);

        return min +' - '+ max;
    }

    
    if(combineType == "sum")
    {
        const _sum = data.reduce((sum, current) => Number.parseFloat(sum)+Number.parseFloat(current), 0);
        return applyFormat(_sum,combineFormat );
    }

    if(combineType == "average")
    {
        const _sum = data.reduce((sum, current) =>  Number.parseFloat(sum)+Number.parseFloat(current), 0);
        return applyFormat(_sum/data.length,combineFormat );
    }


    //fallback to count
    return "combine undefined";
}




export const combileRealEstateAssetsData = (realEstateAssets: any[],lang: string) => {
    //const dataKV: { [key: string]: any} = {};
    const dataKV = new Map<string,{key: string; name: any; type: string; order: number; unit: any; values: any[]; combineType: string; combineFormat: string|null; raw_value: any; value: any}>();
          
    realEstateAssets.forEach(element1 => {
      element1.metadatas.forEach(element2 => {
        
        
        if (element2.combineType !== 'none')
        {
          if(dataKV.has(element2.key))
          {
            //console.log(element2);
            //console.log(dataKV.get(element2.key));
            //console.log(dataKV);
            
            const e = dataKV.get(element2.key);

            if(e !== undefined)
            {
              // eslint-disable-next-line @typescript-eslint/camelcase
              //e.raw_value += element2.raw_value;
              e.values.push( element2.raw_value);
              dataKV[element2.key] = e;
            }
          }
          else
          {
            //dataKV[element2.key] = element2;
            //dataKV.set(element2.key, element2);
            const t = element2.combineType == 'min-max' ? 'min-max' : element2.type
            // eslint-disable-next-line @typescript-eslint/camelcase 
            dataKV.set(element2.key, {key: element2.key, name: element2.name, type: t,order: element2.order, unit: element2?.unit, values: [element2.raw_value], combineType: element2.combineType, combineFormat: element2.combineFormat, raw_value: undefined, value: element2.value});
          }
        }
      });
    });
    //console.log(dataKV);

    dataKV.forEach((v) => {
      // eslint-disable-next-line @typescript-eslint/camelcase  
      v.raw_value = processCombine(v.values, v.combineType, (v?.combineFormat) ?  v.combineFormat : null);
      
      if(v.combineType == 'count')
        v.type= 'number';
      
    });


    //console.log(Array.from( dataKV.values()));

    return Array.from( dataKV.values());
};

