import { createApp} from 'vue'

import { createI18n } from 'vue-i18n'

//import { provideI18n } from "./_helpers/i18nPlugin";
//https://morioh.com/p/cc01fe2e3e02
//https://vuedose.tips/create-a-i18n-plugin-with-composition-api-in-vuejs-3/

import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

import store from './_store'


import  { getBrowserLocales } from './_helpers/Language';

import localization from './locales/localization';

import moment from 'moment';
import numeral from 'numeral';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import './registerServiceWorker';
import config from './_services/config';

import { filter as _filter } from 'lodash';

let defaultLang = 'fr';
let broswerLg = getBrowserLocales( { languageCodeOnly:true});
//console.log('broswerLg', broswerLg);

if(broswerLg !== undefined)
{
  broswerLg = _filter(broswerLg, function(o) { return o in config.languages; });
  //console.log('broswerLg', broswerLg);
  if(broswerLg != undefined && broswerLg.length >0)
  {
    defaultLang = broswerLg[0];
  }
}

//console.log('defaultLang', defaultLang);


// Create VueI18n instance with options
const i18n = createI18n({
  //locale: 'ja', // set locale
  locale: localStorage.getItem('currentLang') || defaultLang,
  fallbackLocale: 'fr', // set fallback locale
  messages: localization, // set locale messages
  // If you need to specify other options, you can set other options
  // ...
})

moment.locale(localStorage.getItem('currentLang') || defaultLang);

// load a locale
numeral.register('locale', 'fr', {
  delimiters: {
      thousands: ' ',
      decimal: ','
  },
  abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
  },
  ordinal : function (number) {
      return number === 1 ? 'er' : 'ème';
  },
  currency: {
      symbol: '€'
  }
});

// switch between locales
numeral.locale('fr');




const app = createApp(App)
  .use(store)
  .use(i18n)
  .use(IonicVue)
  .use(router);

  app.config.globalProperties.$filters = {
    currencyUSD(value: any) {
      return '$' + value
    },
    truncate(text: string, length: number, suffix: string)
    {
      if (text.length > length) {
          return text.substring(0, length) + suffix;
      } else {
          return text;
      }
    },
    getMediaPreview(m)
    {
      if(m)
          {
            if(m.generated_conversions?.thumb512)
              return config.assetsUrl+m.generated_conversions?.thumb512;

            return config.assetsUrl+m.public_url;
          }
          return null;
    },
    formatNumber(value, format='0,0[.]000')
    {
      const n = numeral(value);
      return n.format(format);
    }

  }
  
//app.config.globalProperties.$devMode = (process.env.NODE_ENV !== 'production');
//app.mixin(update);
/*import { Icon } from "leaflet";
  
delete Icon.Default.prototype._getIconUrl;
  
Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png")
  });

*/
router.isReady().then(() => {
  app.mount('#app');
});