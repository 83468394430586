<template>
  <ion-page>
    <ion-content :fullscreen="true" color="light">
      <ion-grid fixed="true">
        <div id="container">
          <div class="lang">
            <lang-btn class="lang-btn"></lang-btn>
          </div>
          <div class="logo">

          </div>
          
          <div class="loging-panel">
            <!-- Default Input -->
            <ion-list>
              <!-- Input with placeholder -->
              <ion-input v-model="inputValueEmailRef" class="login-input" :placeholder="$t('login.input.email')" type="email"></ion-input>
              <ion-input v-model="inputValuePasswordRef" class="login-input"  :placeholder="$t('login.input.password')" type="password"></ion-input>

              <ion-button :disabled="(isInputValid==false)" fill="clear" class="login-btn" @click.prevent="handleSubmit"></ion-button>
            </ion-list>
          </div>
          <!--<ion-button router-link="/home">Go to Home</ion-button>
          <ion-button router-link="/login">Go to Login</ion-button>
          <ion-button router-link="/map">Go to Map</ion-button>
          <ion-button router-link="/dashboard">Go to Dashboard</ion-button> -->
          <div class="footer">
            <a  @click.prevent="ResetPassword" href='#'>{{$t('login.resetPwd')}}</a>
          </div>
        </div>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">


import { IonContent, IonPage, IonGrid, IonButton, IonList, IonInput } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import  LangBtn from '../components/LangBtn.vue';

import config from '../_services/config';

import { Plugins } from '@capacitor/core';

const { Browser } = Plugins;


export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonGrid,
    IonList,
    IonPage,
    //IonItem,
    //IonLabel,
    IonButton,
    IonInput,
    LangBtn
  },
  
  setup() {
      const router = useRouter();
      const inputValueEmailRef = ref('');
      const inputValuePasswordRef = ref('');
      return { 
        router,
        inputValueEmailRef,
        inputValuePasswordRef,
        submitted : false,
      };
  },
  
  computed:
  {
    loggingIn(): boolean {    
      //console.log(this.$store.state.authentication);
      return this.$store.state.authentication.status.loggingIn;
    },
    isInputValid(): string {
      
      return this.inputValueEmailRef && this.inputValuePasswordRef
    }
  },
  methods: {
      handleSubmit (e) {
          this.submitted = true;
          const { inputValueEmailRef, inputValuePasswordRef } = this;
          const { dispatch } = this.$store;
          if (inputValueEmailRef && inputValuePasswordRef) {
              dispatch('authentication/login', { email: inputValueEmailRef, password: inputValuePasswordRef });
          }
      },
      ResetPassword(){
          Browser.open({ url: config.resetPasswordUrl });
      }
  },
  ionViewDidEnter() {
    //console.log('Home page did enter');
    document.title='Rori Partners - Login';
  },
});
</script>

<style scoped>
#container {
  display: flex;
  flex-flow: column;
  text-align: center;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  
}


.lang
{
  min-height: 60px;
  position: relative;
}



.lang-btn
{
  position: absolute;
  width: 60px;
  height: 60px;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  text-align: center;
}
.logo
{
  margin: 5px;
  min-height: 40px;
  background: url( '../../public/assets/img/logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.loging-panel
{
  
  flex: 1;
  display: flex;

}

.loging-panel ion-list
{
  max-width: 400px;
  margin: auto;
  flex: 1;
}

ion-input
{
  
  background-color:var(--ion-color-light);
  margin: 5px 0px;

}

.login-btn
{
  width: 60px;
  height: 60px;
  background: url( '../../public/assets/img/PICT_FlecheDroiteCercle_R.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.footer
{
  min-height: 2em;
  text-align: center;
}
ion-content
{
  background-color: var(--ion-color-light);;
}
ion-grid
{
  background-color:white;
  height: 100%;
  padding: 0px;

}


@media(min-height:512px)
{
    .lang
    {
        max-height: 150px;
    }

    .logo
    {
      margin: 40px;
      max-height: 80px;
    }

    
    .footer
    {
      max-height: 3em;
    }


}


</style>