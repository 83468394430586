
import {  IonItem, IonLabel} from '@ionic/vue';
import { defineComponent } from 'vue';

import moment from 'moment';
import numeral from 'numeral';


export default defineComponent({
  name: 'data-key-item',
  components: {
    
  },
  props:
  {
    type: String,
    label: String,
    value: String,
  },
  setup() {  
    return {  };
  },
  computed: {
    iconStyle(): any
    {
      switch(this.type)
      {
        case 'RealEstateAssetsCount' : return 'assets/img/Pictos/building-line.png';
        case 'RentalArea' : return 'assets/img/Pictos/bar-chart-2-line.png';
        
        case 'Rent' : return 'assets/img/Pictos/calendar-2-line.png';

        case 'TenantCount' : return 'assets/img/Pictos/group-line.png';
        case 'Walt' : return 'assets/img/Pictos/percent-line.png';
        
        case 'BuyingPrice' : return 'assets/img/Pictos/hand-coin-line.png';
        case 'Occupant_Tenant' : return 'assets/img/Pictos/group-line.png';
        case 'BuyingDate' : return 'assets/img/Pictos/calendar-2-line.png';
        case 'ConstructionYear' : return 'assets/img/Pictos/hammer-line.png';
        case 'Status' : return 'assets/img/Pictos/list-check.png';
        


        case 'Investment' : return 'assets/img/Pictos/money-euro-circle-line.png';
        case 'Efficiency' : return 'assets/img/Pictos/wallet-3-line.png';
        case 'Distribution' : return 'assets/img/Pictos/file-chart-line.png';
        

      }
      
      return 'assets/img/Pictos/information-line.png';
    },
    formattedValue(): any
    {
      this.$i18n.locale;
      switch(this.type)
      {
        //case 'RealEstateAssetsCount' : return 'assets/img/Pictos/building-line.png';
        case 'Rent' :
        case 'Investment' :
        case 'Distribution' :
        case 'BuyingPrice' : 
        case 'RentalArea' : return numeral(Number(this.value)).format('0[.]00a');
        case 'Walt' : return numeral(Number(this.value)).format('0[.]00');
        case 'BuyingDate' : return moment(this.value).format('L');
        
      }
      return this.value;
    }
  },
  methods: {
    
  },
});
