export default {
    en: {
      yes:'yes',
      Yes:'Yes',
      no:'no',
      No:'No',
      outdoorseating:"Outdoor seating",
      takeaway:"Takeaway",
      message: {
        hello: 'hello world'
      },
      loading:
      {
        pleaseWait:'Please wait',
      },
      install: {
        title : 'Install',
        message : 'You can install the app to have a better experience',
        button : 'Install',
        cancel : 'Later',
      },
      update: {
        title : 'Update',
        message : 'An update is available',
        button : 'Update',
      },
      network: {
        title : 'Network connectivity change',
        messages : 
        {
          online : 'You are now online',
          offline : 'You are currently offline, some functionality might not be available',
        },
        button : 'OK',
        
      },
      login:
      {
        title:'Rori Partners - Login',
        resetPwd:'Reset Password',
        input:
        {
          email:'email',
          password:'password',
        }
      },
      logout:
      {
        header:'Log out',
        message:'Are you sure you want to log out?',
        buttons:
        {
          cancel:'Cancel',
          no:'No',
          yes:'Yes',
        }
      },
      dashboard:
      {
        yourInvestments:'Your Investments',
        contactUs:'Contact us',
        logOut:'Log out',
        glossaire:'Glossary',
        noDataKeyDoc:
        {
          header:'Document',
          message:'No document available for this item',
        }
      },
      realEstateAsset:
      {
        mapBtn : 'Discover the neighborhood',
        
        map:
        {
          configureLayer:'Configure layers',
          layers:
          {
            restaurants:'Restaurants',
            transports:'Transports',
            sante:'Health',
            banques:'BanKs',
            commerces:'Shops',
            education:'Education',
            loisirs:'Hobbies',
            sports:'Sports',
            hotels:'Hotels',
            entreprises:'Companies',
          }
        }
      
      },
      map:
      {
        allAssets:'All assets',
        segment:
        {
          map:'Map',
          list:'List',
        },
        assets:
        {
          empty:"No assets available"
        },
      },
      components:
      {
        documentList:
        {
          sectionTitle:'Documents'
        },
        dataKey:
        {
          sectionTitle:'Wallet',
          sectionTitle2:'Figures',
          empty:"No data available for this asset"
        },
        welcomPopup:
        {
          welcome: "Welcome ",
          text:"You have just connected to the RORI PARTNERS GROUP portfolio.\n\n You now have access to a personalized selection of real estate assets",
          btn:"Discover the selection",
        },
        documentModal:
        {
          sectionTitle:"Documents",
          closeBtn:"Close",
        },
        userMessages:
        {
          noMessages:"No messages", 
        },
        glossaireModal:
        {
          sectionTitle:"Glossary",
          closeBtn:"Close",
          content:
          {
            transaction:
            {
              title: "Transaction",
              description:"Rental or sale of real estate, materialized by the signing of a lease or a deed of sale, including turnkey. In the event of suspensive conditions, the transaction is taken into account after the lifting of this one.",
            },
            cleEnMain:
            {
              title: "Rental turnkey",
              description: "Construction of a building for a user who will be a tenant. Subsequently, the developer of the building will either sell to an investor or keep it as an asset.",
            },
            comptePropre:
            {
              title: "Own account",
              description: "Construction operation ordered by a user who will own it.",
            },
            valeurLocative:
            {
              title: "Rental value",
              description: "Annual rent per square meter and per year, expressed excluding taxes and charges. Does not include ancillary premises such as parking lots, archives, inter-company restaurant (RIE), etc ... The value used is the face value entered in the lease, not corrected for the effect of any advantages granted by the owner. In the case of progressive rent, the value retained is the average of the values ​​of the first 3 years or of the firm term of the lease.",
            },
            valeurLocativeMoyenne:
            {
              title: "Average rental value",
              description: "Average weighted by rental area. BNP Paribas Real Estate distinguishes new or renovated buildings from second-hand buildings.",
            },
          },
        }
      },
      documents:
      {
        categories:
        {
          Reporting: "Reporting",
          RentalState: "Rental State",
          AssetSheet: "Asset Sheet",
          Tenant: "Tenant",
          
          AssetsMap : "Assets Map",
          WaltReport : "Walt Report",
          AssetsList : "Assets List",
          TenantsSheet: "Tenants Sheet" ,

          
          Micellaous: "Micellaous"
        }
      },
      mailGenerator:
      {
        subject:"[Rori Partners App] Message from {firstname} {name}",
        body:"Hello%0D%0A I am {firstname} {name}, i am writing to you about :%0D%0A%0D%0A%0D%0A%0D%0AE-mail automatically generated from the Rori Partners app.",
      }
    },
    fr: {
      yes:'oui',
      Yes:'Oui',
      no:'non',
      No:'Non',
      outdoorseating:"Sièges d'extérieur",
      takeaway:"Vente à emporter",
      message: {
        hello: 'Bienvenue le monde'
      },
      loading:
      {
        pleaseWait:'Patientez s\'il vous plait',
      },
      install: {
        title : 'Installer',
        message : 'Pour une meilleure experience, installer l\'application',
        button : 'Installer',
        cancel : 'Plus tard',
      },
      update: {
        title : 'Mise à jour',
        message : 'Une nouvelle version est disponible',
        button : 'Relancer',
      },
      network: {
        title : 'Connexion reseau',
        messages : 
        {
          online : 'Vous êtes maintenant en ligne',
          offline : 'Vous êtes hors-ligne, certaines fonctionnalités ne seront pas disponible',
        },
        button : 'OK',
      },
      login:
      {
        title:'Rori Partners - Authentification',
        resetPwd:'Réinitialiser votre mot de passe',
        input:
        {
          email:'email',
          password:'mot de passe',
        }
      },
      logout:
      {
        header:'Déconnexion',
        message:'Voulez-vous vraimment vous déconnecter ?',
        buttons:
        {
          cancel:'Annuler',
          no:'Non',
          yes:'Oui',
        }
      },
      dashboard:
      {
        yourInvestments:'Carte de vos investissements',
        contactUs:'Nous contacter',
        logOut:'Se deconnecter',
        glossaire:'Glossaire',

        noDataKeyDoc:
        {
          header:'Documents',
          message:'Pas de document disponible pour cet élément',
        }
      },
      realEstateAsset:
      {
        mapBtn : 'Découvrir le quartier',
        map:
        {
          configureLayer:'Configurer les calques',
          layers:
          {
            restaurants:'Restaurants',
            transports:'Transports',
            sante:'Santé',
            banques:'Banques',
            commerces:'Commerces',
            education:'Éducation',
            loisirs:'Loisirs',
            sports:'Sports',
            hotels:'Hotels',
            entreprises:'Entreprises',
          }
        }
      },
      map:
      {
        allAssets:'Tous les actifs',
        segment:
        {
          map:'Carte',
          list:'Liste',
        },
        assets:
        {
          empty:"Vous n'avez pas d'actifs disponible"
        },
      },
      components:
      {
        documentList:
        {
          sectionTitle:'Documents'
        },
        dataKey:
        {
          sectionTitle:'Portefeuille',
          sectionTitle2:'Données Chiffrées',
          empty:"Pas de données disponible pour cet actif"
        },
        welcomPopup:
        {
          welcome: "Bonjour ",
          text:"Vous venez de vous connecter au portfolio de RORI PARTNERS GROUP.\n\nVous avez maitenant accès à une sélection personnalisée d'actifs immobilier",
          btn:"Découvrir la sélection",
        },
        documentModal:
        {
          sectionTitle:"Documentation",
          closeBtn:"Retour",
        },
        userMessages:
        {
          noMessages:"Pas de messages", 
        },
        glossaireModal:
        {
          sectionTitle:"Glossaire",
          closeBtn:"Retour",
          content:
          {
            transaction:
            {
              title: "Transaction",
              description:"Location ou vente bien immobilier, concrétisée par la signature d'un bail ou d'un acte de vente, y compris les clés-en-main. En cas de conditions suspensives, la transaction est prise en compte après la levée de celle-ci.",
            },
            cleEnMain:
            {
              title: "Clé-en-main locatif",
              description:"Construction d'un immeuble pour un utilisateur qui en sera locataire. Ultérieurement le promoteur de l'immmeuble soit vendra à un investisseur, soit le gardera en patrimoine.",
            },
            comptePropre:
            {
              title: "Compte propre",
              description:"Opération de construction commandée par un utilisateur qui en sera propriétaire.",
            },
            valeurLocative:
            {
              title: "Valeur locative",
              description:"Loyer annuel par mètre carré et par an, exprimé hors taxes et hors charges. Ne comporte pas les locaux annexes tels que parkings, archives, restaurant inter-entreprises (RIE), etc... La valeur retenue est la valeur faciale inscrite au bail, non corrigée de l'effet d'avantages éventuellement consentis par le propriétaire. En cas de loyer progressif, la valeur retenue est la moyenne des valeurs des 3 premières années ou de la durée ferme du bail.",
            },
            valeurLocativeMoyenne:
            {
              title: "Valeur locative moyenne",
              description:"Moyenne pondérée par la surface à la location. BNP Paribas Real Estate distingue les immeubles neufs ou restructurés des immeubles de seconde main.",
            },
          },
        }
      },
      documents:
      {
        categories:
        {
          Reporting: "Rapport",
          RentalState: "État locatif",
          AssetSheet: "Fiche actif",
          Tenant: "Locataire",
          
          AssetsMap : "Cartographie",
          WaltReport : "Rapport Walt",
          AssetsList : "Listing actifs",
          TenantsSheet: "Fiche Locataires",

          Micellaous: "Divers"
        }
      },
      mailGenerator:
      {
        subject:"[Application Rori Partners] Message de {firstname} {name}",
        body:"Bonjour%0D%0A Je suis {firstname} {name}, et je souhaite m'entretenir avec vous à propos de :%0D%0A%0D%0A%0D%0A%0D%0AMail Généré automatiquement par l'application Rori Partners.",
      }
    }
  }