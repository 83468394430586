export interface BrowserLocalesOptions {
    languageCodeOnly: boolean;
}


export function getBrowserLocales(options?: BrowserLocalesOptions) {
    const defaultOptions: BrowserLocalesOptions = {
        languageCodeOnly: false,
    };

    const opt = {
        ...defaultOptions,
        ...options,
    };

    const browserLocales =
        navigator.languages === undefined
        ? [navigator.language]
        : navigator.languages;

    if (!browserLocales) {
        return undefined;
    }

    return browserLocales.map(locale => {
        const trimmedLocale = locale.trim();

        return opt.languageCodeOnly
        ? trimmedLocale.split(/-|_/)[0]
        : trimmedLocale;
    });
}