
import {  IonButton } from '@ionic/vue';
import { defineComponent } from 'vue';
import { mapState } from 'vuex'



export default defineComponent({
  name: 'welcome-popup',
  emits:['close'],
  components: {
    IonButton,
  },
  setup() {  
    return {  };
  },
  computed: {
    ...mapState<any>({
        user: state =>  state.authentication.user,
        username: function(){
          if(this.user)
            return this.user.first_name;

          return "no user";
          },
    })
  },
  methods: {
    
  },
});
