<template>
  <ion-app>
    <div v-if="devMode" class="dev-mode">Developper mode</div>
    <ion-router-outlet />
  </ion-app>
</template>

<script lang="ts">
import { IonApp, IonRouterOutlet , alertController, toastController} from '@ionic/vue';

import { addIcons } from 'ionicons';
import { closeCircleOutline } from 'ionicons/icons';

import { defineComponent } from 'vue';

//import  update  from './mixins/update.js';

import { NetworkStatus, PluginListenerHandle, Plugins } from '@capacitor/core';

const { Network } = Plugins;


//import axios from 'axios';

//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export default defineComponent({
  name: 'App',

  components: {
    IonApp,
    IonRouterOutlet
  },
  //mixins: [update],
  created() {
    addIcons({
      'close-circle-outline': closeCircleOutline,
    });
  },
  setup()
  {
    //let handler = Network.addListener('networkStatusChange', this.networkStatusChange );
    // To stop listening:
    // handler.remove();

    
    //const offlineToast: HTMLIonToastElement|null = null;
    return {
      
      refreshing: false,
      registration: null as any,
      deferredPrompt: null as any,
      updateExists:false,
      handler:null as PluginListenerHandle|null,
      offlineToast: null as HTMLIonToastElement|null,
      installToast: null as HTMLIonToastElement|null,
    }
  },
  mounted()
  {
    
    this.handler = Network.addListener('networkStatusChange', this.networkStatusChange );

    console.log('addEventListener swUpdated');
    //document.addEventListener('swUpdated', this.updateAvailable, { once: true });
    document.addEventListener('swUpdated', this.updateAvailable);

    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      
      this.showInstallPromotion();
    });

  
        // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      // Here the actual reload of the page occurs
      window.location.reload()
    });
    
    //test
    //this.showInstallPromotion();
  },
  unmounted()
  {
    if(this.handler)
      this.handler.remove();
  },
  computed:
  {
    alert(){
      return this.$store.state.alert.type;
    },
    devMode()
    {
      return (process.env.NODE_ENV !== 'production');
      
    }
  },
  watch:{
    alert()
    {
      //console.log('alert', this.$store.state.alert);
      if(this.alert)
        this.presentAlert();
    },
    updateExists()
    {
      console.log('watch updateExists', this.updateExists);
      //this.updateExistsAsync();
    }
  },
  methods: {


    async presentAlert() {
      
      

      const alert = await alertController
        .create({
          cssClass: 'app-alert',
          header: this.$store.state.alert.title,
          //subHeader: 'Subtitle',
          message: this.$store.state.alert.message,
          buttons: [
            {
              text: 'OK',
              handler: () => {
                console.log('Confirm Okay');
                const { dispatch } = this.$store;
                dispatch('alert/clear',null, { root: true });
                
              },
            },
          ],
        });
      return alert.present();
    },
    async openOfflineToast() {
      if(this.offlineToast !== null)
        return;

      this.offlineToast = await toastController
        .create({
          cssClass: 'app-toast',
          header: this.$t('network.title'),
          message: this.$t('network.messages.offline'),
          position: 'top',
          buttons: [
            {
              text: this.$t('network.button'),
              handler: () => {
                
                this.dismissOfflineToast();
              }
            },
          ]
        })
      return this.offlineToast.present();
    },
    async dismissOfflineToast() {
      if(this.offlineToast !== null)
      {
        this.offlineToast.dismiss().then( () => {this.offlineToast = null;} );
      }
    },
    async openOnlineToast() {
      this.dismissOfflineToast();

      const toast = await toastController
        .create({
          cssClass: 'app-toast',
          header: this.$t('network.title'),
          message: this.$t('network.messages.online'),
          position: 'top',
          duration: 2000,
        })
      return toast.present();
    },
    networkStatusChange(status: NetworkStatus)
    {
      console.log("Network status changed", status);
      if(status.connected)
      {
        this.openOnlineToast();
      }
      else
      {
        this.openOfflineToast();
      }
    },
    updateAvailable(event){
      console.log('new updateAvailable event', event);
      this.registration = event.detail;
      this.updateExists = true;
      this.updateExistsAsync();
    },
    refreshApp(){
      this.updateExists = false
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return
      // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    async updateExistsAsync()
    {
      console.log("watch updateExists");
      if(this.updateExists == true)
      {
        console.log("new update");
        const updateToast = await toastController
        .create({
          cssClass: 'app-toast',
          message: this.$t('update.message'),
          position: 'bottom',
          buttons: [
            {
              text: this.$t('update.button'),
              handler: () => {
                this.refreshApp();
              }
            },
          ]
        })
        return updateToast.present();
      }
    },
    async showInstallPromotion() {
      if(this.deferredPrompt === null)
        return;

      console.log('deferredPrompt', this.deferredPrompt);

      this.installToast = await toastController
        .create({
          cssClass: 'app-toast',
          //header: this.$t('install.title'),
          message: this.$t('install.message'),
          position: 'bottom',
          buttons: [
            {
              text: this.$t('install.button'),
              handler: () => {
                
                console.log('Installing', this.deferredPrompt.platforms); // e.g., ["web", "android", "windows"]
                
                // Show the install prompt
                this.deferredPrompt.prompt();
                // Wait for the user to respond to the prompt
                this.deferredPrompt.userChoice.then((choiceResult) => {
                  if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                  } else {
                    console.log('User dismissed the install prompt');
                  }
                  this.deferredPrompt = null;
                });
                this.installToast?.dismiss();
              }
            },
            {
              side:'end',
              icon:'close-circle-outline',
              role:'cancel',
            }
          ]
        })
      return this.installToast.present();
    },
  }

});

      
      
      

</script>

<style>
:root{
  --container-responsive-max-width : 720px;
}
.app-toast
{
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;
  --ion-font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;
}

.app-toast-center
{
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;
  --ion-font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;
}

.app-alert
{
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;
  --ion-font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;
}

.app-alert-lang
{
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;
  --ion-font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;
}

.app-alert-lang .alert-wrapper
{
  padding-bottom : 18px;
}

.app-alert-lang .alert-wrapper .alert-head
{
  padding-top: 18px;
  padding-bottom : 0px;
}

.app-alert-lang .alert-wrapper .alert-message
{
  padding-top: 0px;
  padding-bottom : 0px;
}


.app-alert-lang .alert-wrapper .alert-radio-group
{
  border-top: none;
  border-bottom : none;
}

.app-alert-lang .alert-button-group
{
  padding-top: 0px;
  padding-bottom : 0px;
}

.app-loading
{
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;
  --ion-font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;
}

.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-modal-gallery
{
  //--width: 600px;
  //--height: 600px;
}

.app-modal-bottom .modal-wrapper
{
  position: absolute;
  display: block;
  top: calc(100% - (200px));
  --height: 200px;
  --border-radius: 10px 10px 0px 0px;
  --box-shadow: 0px -0.5px 2px 1px rgba(0, 0, 0, 0.1);
}

.cupertino-pane-wrapper
{
  z-index: 500;
}

.dev-mode
{
  position: absolute;
  
  right: 0;
  top: 0;
  text-align: center;
  font-size: 0.6em;
  z-index: 5000;
  color: lightgray;
  pointer-events: none;
}


@media only screen and (min-width: 992px) and (min-height: 768px)
{
  .app-modal-gallery
  {
    --width: 800px;
    --height: 600px;
  }
}

@media only screen and (min-width: 992px) and (min-height: 992px)
{
  .app-modal-gallery
  {
    --width: 800px;
    --height: 800px;
  }
}

@media only screen and (min-width: 1200px) and (min-height: 992px)
{
  .app-modal-gallery
  {
    --width: 1024px;
    --height: 800px;
  }
}

@media only screen and (min-width: 1200px) and (min-height: 1200px)
{
  .app-modal-gallery
  {
    --width: 1024px;
    --height: 1024px;
  }
}


</style>