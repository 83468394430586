


import { IonContent, IonPage, IonGrid, IonButton, IonList, IonInput } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import  LangBtn from '../components/LangBtn.vue';

import config from '../_services/config';

import { Plugins } from '@capacitor/core';

const { Browser } = Plugins;


export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonGrid,
    IonList,
    IonPage,
    //IonItem,
    //IonLabel,
    IonButton,
    IonInput,
    LangBtn
  },
  
  setup() {
      const router = useRouter();
      const inputValueEmailRef = ref('');
      const inputValuePasswordRef = ref('');
      return { 
        router,
        inputValueEmailRef,
        inputValuePasswordRef,
        submitted : false,
      };
  },
  
  computed:
  {
    loggingIn(): boolean {    
      //console.log(this.$store.state.authentication);
      return this.$store.state.authentication.status.loggingIn;
    },
    isInputValid(): string {
      
      return this.inputValueEmailRef && this.inputValuePasswordRef
    }
  },
  methods: {
      handleSubmit (e) {
          this.submitted = true;
          const { inputValueEmailRef, inputValuePasswordRef } = this;
          const { dispatch } = this.$store;
          if (inputValueEmailRef && inputValuePasswordRef) {
              dispatch('authentication/login', { email: inputValueEmailRef, password: inputValuePasswordRef });
          }
      },
      ResetPassword(){
          Browser.open({ url: config.resetPasswordUrl });
      }
  },
  ionViewDidEnter() {
    //console.log('Home page did enter');
    document.title='Rori Partners - Login';
  },
});
