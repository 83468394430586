import axios, { AxiosRequestConfig } from 'axios';
import localforage from 'localforage';
import md5 from 'md5';
import { cloneDeep as _cloneDeep } from 'lodash';

function CreateOverpassInterceptor()
{
    // create new axios instance
    const api = axios.create({});
    const cacheUpdateApi = axios.create({});

    const MAX_REQUESTS_COUNT = 1;
    const INTERVAL_MS = 10;
    const DELAY_MS = 3*1000;
    const CACHE_UPDATE_DELAY_MS = 10*1000;

    //30 days of cache age
    const MAX_CACHE_AGE_MS = 30*24*3600*1000;
    //const MAX_CACHE_AGE_MS = 30*1000;
    
    let PENDING_REQUESTS = 0;
    let LAST_RESPONSE = 0;

    //Cache Store
    const cacheStore = localforage.createInstance({
        name: "OverpassCache"
      });

    class CacheItem
    {
        date: number = Date.now();
        content: any;
    }

    function cleanCacheStorage()
    {
        cacheStore.iterate<CacheItem,void>(function(value, key, iterationNumber) {
            // Resulting key/value pair -- this callback
            // will be executed for every item in the
            // database.
            //console.log("clean test",[key, value]);
            const now = Date.now();
            if( now > value.date + MAX_CACHE_AGE_MS)
            {
                //console.log("cleaning cache",[key, value]);
                cacheStore.removeItem(key);
            }
        });
        /*
        .then(function() {
            console.log('Iteration has completed');
        }).catch(function(err) {
            // This code runs if there were any errors
            console.log(err);
        });
        */
    }

    function isCachable(config: AxiosRequestConfig): boolean
    {
        return config.method == 'get';
    }

    function serializeQuery (req: AxiosRequestConfig): string
    {
        if (!req.params) return ''
      
        // Probably server-side, just stringify the object
        if (typeof URLSearchParams === 'undefined') return JSON.stringify(req.params)
      
        let params = req.params
      
        const isInstanceOfURLSearchParams = req.params instanceof URLSearchParams
      
        // Convert to an instance of URLSearchParams so it get serialized the same way
        if (!isInstanceOfURLSearchParams) {
          params = new URLSearchParams()
          Object.keys(req.params).forEach(key => params.append(key, req.params[key]))
        }
      
        return `?${params.toString()}`
    }
    function getCacheKey(config: AxiosRequestConfig): string
    {
        const url = `${config.baseURL ? config.baseURL : ''}${config.url}`
        const key = url + serializeQuery(config)
        return config.data ? key + md5(config.data) : key
    }
    

    async function getCacheItem(config: AxiosRequestConfig): Promise<CacheItem | null>
    {
        const key = getCacheKey(config);

        const c = await cacheStore.getItem<CacheItem>(key);
        
        return c;
    }
    async function setCacheItem(config: AxiosRequestConfig, data: any): Promise<CacheItem>
    {
        const key = getCacheKey(config);

        const c = new CacheItem();
        c.date = Date.now();
        c.content = data;

        return cacheStore.setItem<CacheItem>(key,c);
    }

    /**
     * Axios Request Interceptor
     */
    api.interceptors.request.use(function (config) {
        return new Promise((resolve, reject) => {
        const interval = setInterval(async () => {
            const now = Date.now();
            if ((PENDING_REQUESTS < MAX_REQUESTS_COUNT) && (now > (LAST_RESPONSE+DELAY_MS)) ) {
            PENDING_REQUESTS++;
            clearInterval(interval);

            if(isCachable(config))
            {
                const cache = await getCacheItem(config);
                if(cache != null)
                {
                    //console.log("[api.interceptors.request] found cache");
                    //create a new request for updating the cache
                    cacheUpdateApi.request(_cloneDeep(config));

                    config.data = cache.content;
                    
                    // Set the request adapter to send the cached response and prevent the request from actually running
                    config.adapter = () => {
                        return Promise.resolve({
                            data: cache.content,
                            status: 200,
                            statusText: 'OK',
                            isFromCache: true,
                            headers: config.headers,
                            config: config,
                            request: config
                        });
                    };
                }
            }

            resolve(config);
            } 
        }, INTERVAL_MS);
        })
    });
    /**
     * Axios Response Interceptor
     */
    api.interceptors.response.use(async function (response) {
        
        //console.log("[api.interceptors.response]",response);
        PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
        LAST_RESPONSE = Date.now();

        if( !('isFromCache' in response) )
        {
            //console.log("[api.interceptors.response] saving request");
            const key = getCacheKey(response.config);
            await setCacheItem(response.config, response.data);
        }
        
        return Promise.resolve(response);
    }, function (error) {
        PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
        LAST_RESPONSE = Date.now();
        return Promise.reject(error);
    });


    /**
     * Axios Request Interceptor
     */
     cacheUpdateApi.interceptors.request.use(function (config) {
        return new Promise((resolve, reject) => {
        const interval = setInterval(() => {
            const now = Date.now();
            if ((PENDING_REQUESTS < MAX_REQUESTS_COUNT) && (now > (LAST_RESPONSE+CACHE_UPDATE_DELAY_MS)) ) {
            PENDING_REQUESTS++;
            clearInterval(interval);
            //console.log("[cacheUpdateApi.interceptors.request] request update cache");
            resolve(config);
            } 
        }, INTERVAL_MS);
        })
    });
    /**
     * Axios Response Interceptor
     */
     cacheUpdateApi.interceptors.response.use(async function (response) {
        
        //console.log("[cache_update_api.interceptors.response]",response);
        PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
        LAST_RESPONSE = Date.now();

        const key = getCacheKey(response.config);
        await setCacheItem(response.config, response.data);

        return Promise.resolve(response);
    }, function (error) {
        PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
        LAST_RESPONSE = Date.now();
        return Promise.reject(error);
    });

    cleanCacheStorage();

    return api;
}


export default CreateOverpassInterceptor();