<template>
  <ion-header>
    <ion-toolbar>
        <ion-title class="title">{{ title }}</ion-title>
        <ion-buttons slot="end">
            <ion-button color="primary" @click="dismissModal()">{{$t('components.documentModal.closeBtn')}}</ion-button>
        </ion-buttons>      
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
   <lightbox :startIndex="startIndex" :items="itemList" />
  </ion-content>
</template>

<script>
import { IonContent, IonHeader, IonToolbar,IonTitle, IonButton,IonButtons, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';

import moment from 'moment';
import config from '@/_services/config';
import { Browser } from '@capacitor/core';

import Lightbox from './Lightbox/Lightbox.vue'

export default defineComponent({
  name: 'DocumentModal',
  components: { 
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButton,
    IonButtons,
    Lightbox,
    },
  props: {
    startIndex: Number,
    title:{type:String},
    pictures:{type:Array}
  },
  data() {
    return {
      content: 'Content',
    }
  },
  computed: {

        itemList: function(){
          return this.pictures.map(i=> {return  {name: i.name, type: i.type, url: config.assetsUrl+i.public_url }});
        },
  },
  methods:
  {
    dismissModal()
    {
        modalController.dismiss();
    },
    openDoc(doc)
    {
        console.log("opening",doc.public_url);
        Browser.open({ url: config.assetsUrl+doc.public_url });
    },
    computeddate(date) {
        return moment(date).format('L') ;
    }
  }
  
});
</script>

<style scoped>


.label-filename
{
    font-weight: 700;
}

.label-date
{
    text-align: end;
    max-width: 80px;
}

</style>