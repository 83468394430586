
import {  
  IonSlide,
  } from '@ionic/vue';

import { defineComponent } from 'vue';


export default defineComponent({
  name: 'lightbox-item',
  components: {
     IonSlide,

  },
  props: {
    active : {type:Boolean, default:true},
    type: {type:String},
    url: {type:String},
    title: {type:String},
    message: {type:String}
  },
});






