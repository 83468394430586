<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
      <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <div v-if="user" fixed="true">
        <div class="profile-picture" :style="profilePictureCss"></div>
        <div class="container">
          
          <lang-btn class="lang-btn"></lang-btn>
          <div class="user-panel">
            <ion-avatar v-if="avatar">
              <img class="user-avatar" :src="avatar">
            </ion-avatar>
            <div class="user-name">
              {{username}}
            </div>
            
            <!--ion-button color="danger" router-link="/login" >Se déconnecter</ion-button-->
          </div>
          
          <user-messages v-if="messages != null" :messages="messages"></user-messages>
          
          
          <data-key-icon class="data-key" :data="userData" @show="showDataKey"></data-key-icon>
          
          <!-- <ion-button class="map-btn" expand="full" shape="round" :router-link="{'name':'Map'}" >{{$t('dashboard.yourInvestments')}}<img src="../../public/assets/img/PICT_FlecheDroite_R.png"></ion-button> -->

          <h3 v-if="user.assets.length >0">{{$t('components.documentList.sectionTitle')}}</h3>
          <document-list class="doc-list" :documents="user.assets"></document-list>
          <ion-button class="map-btn glossaire" expand="full" shape="round" @click="openGlossaireModal">{{$t('dashboard.glossaire')}}</ion-button>
          <div class="contact">
            <p class="contact-text">{{$t('dashboard.contactUs')}}</p>
            <div class="contact-list">
              <ion-button class="contact-btn" @click="clickMail" ><img src="../../public/assets/img/PICT_Mail_R.png"></ion-button>
              <ion-button class="contact-btn" @click="clickPhone" ><img src="../../public/assets/img/PICT_Telephone_R.png"></ion-button>
              <ion-button class="contact-btn" @click="clickWeb" ><img src="../../public/assets/img/PICT_Web_R.png"></ion-button>
            </div>
          </div>

          <div class="footer">
            <a @click.prevent="presentLogoutDialog()" href='#' >{{$t('dashboard.logOut')}}</a>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonPage, IonButton,IonAvatar, loadingController, IonRefresher, IonRefresherContent,alertController, modalController, toastController } from '@ionic/vue';
import { useRouter } from 'vue-router';

import { defineComponent } from 'vue';
import { mapState, mapActions } from 'vuex'

import { logOutOutline, removeCircleOutline } from 'ionicons/icons';  

import  LangBtn from '../components/LangBtn.vue';
import  UserMessages from '../components/UserMessages.vue';
import  DataKeyIcon from '../components/DataKeyIcon/DataKeyIcon.vue';
import  DocumentList from '../components/DocumentList.vue';

import { EmailPluginWeb } from '../_helpers/EmailPlugin';

import DocumentModal from '../components/DocumentModal.vue';

import GlossaireModal from '../components/GlossaireModal.vue';

//import {myIcon,myIcon2 } from '../components/map/MapIcons';

import config from '../_services/config';
import { App, Plugins } from '@capacitor/core';

const { Browser } = Plugins;


import {createImageFromInitials} from '../_helpers/profilePictureGenerator';

import {combileRealEstateAssetsData} from '../_helpers/combineRealEstateAssetsData';

//const getRandomColor = require('../_Helpers/ProfilePictureGenerator.js');

import {union as _union, cloneDeep as _cloneDeep, sortBy as _sortBy , filter as _filter } from 'lodash';

export default defineComponent({
  name: 'dashboard',
  components: {
    IonContent, IonRefresher, IonRefresherContent,
    //IonGrid,
    IonPage,
    IonButton,
    IonAvatar,
    LangBtn,
    UserMessages,
    DataKeyIcon,
    DocumentList,
    

  },
  data()
  {
    
    const loading: HTMLIonLoadingElement|any = null;
    const displayMap = 'map';
    const displayList = 'list';
    return { loading, displayMap, displayList}
  },
  setup() {
    const router = useRouter();

    const profilePictureCss ={};
    //return { router, mailOutline, callSharp,logOutOutline , user, profilePictureCss};
    return { router, logOutOutline , removeCircleOutline, profilePictureCss};
  },
  computed: {
    ...mapState<any>({
        user: state =>  state.authentication.user,
        realEstateAssets: state =>  state.authentication.realEstateAssets,
        messages: state =>  state.authentication.messages,
        username: function(){
          if(this.user)
            return this.user.first_name +' '+this.user.name;

          return "no user";
          },
        avatar: function(){
          if(this.user && this.user.avatar)
          {
            if(this.user.avatar.generated_conversions?.thumb128)
              return config.assetsUrl+this.user.avatar.generated_conversions?.thumb128;

            return config.assetsUrl+this.user.avatar.public_url;
          }
          return null;
        },
        userData:function()
        {
          let r: any[] = [];

          if(Array.isArray( this.realEstateAssets))
          {
            
            
            try
            {
              r = combileRealEstateAssetsData(this.realEstateAssets,this.$i18n.locale);
            }
            catch(error)
            {
              console.error(error);
              r= [];
            }
            
            r.push( {
              combineType: "sum",
              order:0,
              key: "RealEstateAssetsCount",
              name: {en: "Assets", fr: "Actifs"},
              type: "number",
              unit: null,
              // eslint-disable-next-line @typescript-eslint/camelcase
              raw_value: this.realEstateAssets.length,
              value: 
              {
                en:this.realEstateAssets.length,
                fr:this.realEstateAssets.length,
              },
              values: [],
            });
            
            //console.l"g("r",r);
            
            //return r;
          }

          //Combine with user data
          r = _union(r,_cloneDeep( this.user.metadatas));
          //r = _merge(_cloneDeep(r), this.user.metadatas);
          r = _sortBy(r,[function(o) { return o.order; }]) ;
          console.log("meta",r);

          return r;
        }
    })
  },
  async created () {
    const colorBg = '#a6230e'; //getRandomColor();
    this.profilePictureCss =   {
          
            
            'background-position': 'bottom',
            'background-repeat': 'no-repeat',
            'background-size': 'contain',
            'background-color': colorBg,
      };

      
  },
  methods: {
    updateProfile()
    {
      console.log("updateProfile",);
      const colorBg = '#a6230e'; //getRandomColor();
      const colorFg = '#af3926'; //getRandomColor();
      this.profilePictureCss =   {
          
            'background-image' :  'url(' + createImageFromInitials(200,this.username,colorBg, colorFg) +')',
            'background-position': 'bottom',
            'background-repeat': 'no-repeat',
            'background-size': 'contain',
            'background-color': colorBg,
            };
    },
    clickWeb()
    {
      //console.log('Click Mail');
      //this.markerState = !this.markerState;
      Browser.open({ url: config.contact.web });
      //App.openUrl({ url: config.contact.web });
    },
    clickMail()
    {
      //console.log('Click Mail');
      //this.markerState = !this.markerState;
      //Browser.open({ url: config.contact.mail });
      //App.openUrl({ url: config.contact.mail });

      EmailPluginWeb.open({ 
        to: [config.contact.mail],
        subject : this.$t('mailGenerator.subject', {firstname:this.user.first_name, name: this.user.name }),
        body: this.$t('mailGenerator.body',{firstname:this.user.first_name, name: this.user.name })
        } );

    },
    clickPhone()
    {
      //console.log('Click Phone');
      Browser.open({ url: config.contact.phone });
    },
    ...mapActions('authentication', {
        getUser: 'getUser',
        getCompany: 'getCompany',
        getPortfolios: 'getPortfolios',
        getMessages: 'getMessages',
        getRealEstateAssets: 'getRealEstateAssets',
        logout: 'logout'
    }),
    async presentLoading() {
      //console.log("Dashboard presentLoading", this.loading);
      if(this.loading !== null)
        return;

      this.loading = await loadingController
        .create({
          cssClass: 'app-loading',
          message: this.$t('loading.pleaseWait'),
          //duration: this.timeout,
        });

      //console.log("Dashboard presentLoading 2", this.loading);
      await this.loading.present();

    },
    dismissLoading()
    {
      //console.log("Dashboard dismiss", this.loading);
      if(this.loading !== null)
      {
        //console.log("Dashboard dismiss 2");
        this.loading.dismiss();
        this.loading = null;
      }
    },
    doRefresh(event: CustomEvent)
    {
      if(event.target !== null)
      {
        //this.getUser().finally(() => (event.target as unknown as typeof IonRefresher).complete());  
        Promise.all([this.getUser(), this.getCompany(), this.getPortfolios(), this.getMessages(), this.getRealEstateAssets()]).then(() => {
          //console.log(values);
          (event.target as unknown as typeof IonRefresher).complete();  
        }).catch(() => {
          //console.log(reason);
          (event.target as unknown as typeof IonRefresher).complete();
        });
      }
    },
    async presentLogoutDialog()
    {
        const alert = await alertController
        .create({
          cssClass: 'app-alert',
          header: this.$t('logout.header'),
          message: this.$t('logout.message'),
          buttons: [
            {
              text: this.$t('logout.buttons.no'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: blah => {
                console.log('Confirm Cancel:', blah)
              },
            },
            {
              text: this.$t('logout.buttons.yes'),
              handler: () => {
                console.log('Confirm Okay');
                this.logout().finally( () => this.router.push('/login'));
              },
            },
          ],
        });
      return alert.present(); 
    },
    async openGlossaireModal() {
      const modal = await modalController
        .create({
          component: GlossaireModal,
          cssClass: 'my-custom-class',
        })
      return modal.present();
    },
    showDataKey(key: string)
    {
      //console.log("show datakey", key);

      switch(key)
      {
        
        //case 'RealEstateAssetsCount' : this.showDataDoc('AssetsMap');break;
        case 'RealEstateAssetsCount' : this.router.push({'name':'Map', 'params':{ display :'map'}});break;
        //case 'RentalArea' : this.showDataDoc('AssetsList');break;
        case 'RentalArea' : this.router.push({'name':'Map', 'params':{ display :'list'}});break;
        
        //case 'Occupant_Tenant' : this.showDataDoc('TenantsSheet');break;
        case 'Occupant_Tenant' : this.showDataDoc('TenantsSheet');break;
        case 'Investment' : this.showDataDoc('Investment');break;
        case 'Distribution' : this.showDataDoc('Distribution');break;
        case 'Efficiency' : this.showDataDoc('Efficiency');break;
        case 'Walt' : this.showDataDoc('WaltReport');break;
        case 'BuyingPrice' : this.showDataDoc('BuyingPrice');break;

        
      }
    },
    async showDataDoc(key: string)
    {
      const filteredAssets = _filter(this.user.assets, function(o) { return (o.custom_properties?.category  ?? "Micellaous") == key; });
      //console.log('showDataDoc', asset);
      if(filteredAssets == undefined || filteredAssets.length == 0)
      {
        //doc not found show alert
        const toast = await toastController.create({
          cssClass: 'app-toast-center',
          //header: this.$t('dashboard.noDataKeyDoc.header'),
          message: this.$t('dashboard.noDataKeyDoc.message'),
          position: 'middle',
          duration: 2000
        });
        toast.present();
      }
      else
      {
        if(filteredAssets.length == 1)
        {
          Browser.open({ url: config.assetsUrl+filteredAssets[0].public_url });
        }
        else
        {
          const modal = await modalController
          .create({
            component: DocumentModal,
            cssClass: 'my-custom-class',
            componentProps: {
              documents: filteredAssets,
            },
          })
          return modal.present();
        }
        
      }
    }
  },
  watch: {
    user: function(){
      if(this.user)
      {
        console.log("Updated username", this.username);
        this.updateProfile();
      }
      else
      {
        //router.push('/login');
      }
    }
  },
  async ionViewDidEnter() {
    //console.log('Home page did enter');
    //document.title='Rori Partners - Dashboard';
    await this.presentLoading();
      
      //this.getUser().finally(() => this.dismissLoading());  
      Promise.all([this.getUser(), this.getCompany(), this.getPortfolios(),this.getMessages(), this.getRealEstateAssets()]).then(() => {
        //console.log(values);
        this.dismissLoading();  
      }).catch(() => {
        //console.log(reason);
        this.dismissLoading();  
      });

  },

  
   
});
</script>


<style scoped>

ion-content
{
  --background : rgb(239, 239, 239);
  
}

.container
{
  margin: auto;
  position: relative;
  max-width: var(--container-responsive-max-width);
  padding: 10px 10px;
}
.profile-picture
{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 160px;
  
}

.lang-btn
{
  position: absolute;
  right: 0;
  width: 60px;
  height: 30px;
  --color: white;
  
}



.user-panel
{
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  color: white;
  font-size:1.3em;
}

.user-avatar{
  
}

.user-name{
  margin: 5px 10px;
  text-align: center;
  font-size:1.2em;
  
}

.icon-logout
{
  vertical-align: bottom;
  transform: rotateZ(90deg);
}

.data-key
{
  margin: auto;
  max-width: 620px;
}

.contact
{
  margin: 20px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.contact-text
{
  width: 100%;
  text-align: center;
}
.contact-btn
{
  width: 60px;
  height: 60px;
  border: 1px solid var(--ion-color-primary);
  border-radius: 5px;
  margin: 0px 10px;
  --background: white;
}
.map-btn
{
  margin: 40px auto;
  margin-top: 10px;

  height: 40px;
  border: 1px solid var(--ion-color-primary);
  border-radius: 20px;
  --border-radius:20px;
  --background: white;
  color: var(--ion-color-primary);
  font-size: 0.8em;
}

.map-btn img
{
  height:60%;
}

.doc-list
{
  max-height: 300px;
  overflow: auto;
  //border-style: solid;
  //border-color: lightgray;
  //border-radius: 20px;
  //border-width: 1px;
}
.footer
{
    text-align: center;
    margin: 20px;
}

.glossaire
{
  margin-top : 20px;
}

@media(min-width:540px)
{
  .map-btn
  {
    font-size: 1.2em;
  }

  .map-btn img
  {
    height:80%;
  }

  .profile-picture
  {
    height: 200px;
  }

  .user-panel
  {
    padding-top: 60px;
  }

  .lang-btn
  {
    
    width: 60px;
    height: 60px;
    
  }

  
}

</style>