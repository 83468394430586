<template>
  <div>
    <ion-button class="btn" fill="clear"  @click="presentAlert">{{currentLanguage}}</ion-button>
  </div>
</template>

<script lang="ts">
import config from '@/_services/config';
import {  IonButton, alertController } from '@ionic/vue';
import moment from 'moment';
import numeral from 'numeral';
//import { key } from 'ionicons/icons';
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'lang-btn',
  components: {
    IonButton,
  },
  setup() {  
    // eslint-disable-next-line prefer-const
    //let alert:  = null;
    return { alert: null as HTMLIonAlertElement | null  };
  },
  computed:
  {
    currentLanguage()
    {
      return this.$i18n.locale;
    },
  },
  methods: {
    async presentAlert() {

      if(this.alert != null)
      {
        //alert already present
        return;
      }

      const inputs: any[] = [];
      
      const keys = Object.keys(config.languages);
      
      for(let i = 0; i<keys.length; i++)
      {
        //console.log(key,config.languages[key]);
    
        const it = {
            type: 'radio',
            label: config.languages[keys[i]],
            value: keys[i],
            checked: keys[i]===this.currentLanguage,
            handler: (data) => {
                this.changeLangagues(data.value);
              },
          };
        
        inputs.push(it);
      }

      this.alert = await alertController
        .create({
          cssClass: 'app-alert-lang',
          inputs: inputs,
        });

      this.alert.onDidDismiss().then( () => { this.alert = null;});
      return this.alert.present();
    },
    changeLangagues(newLang: string)
    {
      console.log('new lang', newLang);
      this.$i18n.locale = newLang;
      moment.locale(newLang);
      numeral.locale(newLang);

      localStorage.setItem('currentLang',newLang);
      if(this.alert != null)
      {
        this.alert.dismiss();
      }
    },
  }
});
</script>

<style scoped>

.btn
{
    width: 100%;
    height: 100%;
    --color: inherit;
}

</style>