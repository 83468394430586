
import {  IonList } from '@ionic/vue';
import { defineComponent } from 'vue';
import DocumentListItem from './DocumentListItem.vue';



export default defineComponent({
  name: 'document-list',
  props:
  {
    documents:{type:Array}
  },
  components: {
    IonList,
    
    DocumentListItem
    
  },
  setup() {  
    
    return { 
      
     };
  },
  methods: {
    
  },
});
