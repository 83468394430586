<template>
  <ion-header>
    <ion-toolbar>
        <ion-title>{{ $t('components.documentModal.sectionTitle') }}</ion-title>
        <ion-buttons slot="end">
            <ion-button color="primary" @click="dismissModal()">{{$t('components.documentModal.closeBtn')}}</ion-button>
        </ion-buttons>      
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <template v-for="(vdoc,k) in groupDocuments" :key="k">
      <ion-list>
        <ion-list-header class="label-header">
          {{ $t('documents.categories.'+k)}}
        </ion-list-header>
        <ion-item  class="btn" v-for="m in vdoc" :key="m.id" @click="openDoc(m)">
          <ion-label  class="label-filename">{{m.name}}</ion-label>
          <ion-note class="label-date" slot="end">{{computeddate(m.updated_at)}}</ion-note>
        </ion-item>
      </ion-list>
    </template>
  </ion-content>
</template>

<script>
import { IonContent, IonHeader, IonTitle, IonToolbar, IonLabel, IonNote,IonButton,IonButtons, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';

import moment from 'moment';
import config from '@/_services/config';
import { Browser } from '@capacitor/core';




export default defineComponent({
  name: 'DocumentModal',
  components: { 
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonLabel,
    IonNote,
    IonButton,
    IonButtons,
    },
  props: {
    documents:{type:Array}
  },
  data() {
    return {
      content: 'Content',
    }
  },
  computed:
  {
    groupDocuments: function()
    {
      
      const doc = this.documents.reduce(function(rv, x) {
          const c = x.custom_properties?.category  ?? "Micellaous";
          (rv[c] = rv[c] || []).push(x);
          return rv;
        }, {});
      console.log(doc);
      return doc;
    }
  },
  methods:
  {
    dismissModal()
    {
        modalController.dismiss();
    },
    openDoc(doc)
    {
        console.log("opening",doc.public_url);
        Browser.open({ url: config.assetsUrl+doc.public_url });
    },
    computeddate(date) {
        return moment(date).format('L') ;
    }
  }
  
});
</script>

<style scoped>

.label-header
{
  font-weight: 700;
  font-size: 1.2em;
  color: darkgray;
}

.label-filename
{
    font-weight: 600;
}

.label-date
{
    text-align: end;
    max-width: 80px;
}

</style>