<template>
  <ion-page>
    
    <ion-content :fullscreen="true">
      
      <div id="container">
          <div class="cmd-btn" v-on="{ mousedown: handleCmdMouseDown, mouseup: handleCmdMouseUp }">
            <lang-btn class="lang-btn"></lang-btn>
            <dashboard-btn class="dashboard-btn"></dashboard-btn>
            <ion-segment class="segment-control"  v-model="segmentValue">
              <ion-segment-button class="segment-btn segment-btn-map" value="map">
                <ion-label>{{$t('map.segment.map')}}</ion-label>
              </ion-segment-button>
              <ion-segment-button class="segment-btn segment-btn-list" value="list">
                <ion-label>{{$t('map.segment.list')}}</ion-label>
              </ion-segment-button>
            </ion-segment>
          </div>
        
          <l-map id="map" class="map" ref="map" :center="mapCenter" v-model:zoom="mapZoom" :zoomAnimation="true" :fadeAnimation="true" :markerZoomAnimation="true" :inertia="true" @ready="mapReady()">
            <div ref="mapBoundsContainer" class="map-bounds-container">
              <div ref="mapBounds" class="map-bounds"></div>
            </div>
            <l-tile-layer
             url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png"
             >
            </l-tile-layer>
              <l-marker v-for="r in realEstateAssets"  :ref="'markerPopup_'+r.id" :key="r.id" :lat-lng="[r.gps_coordinates.latitude, r.gps_coordinates.longitude]" @click="markerClick(r)">
                <l-popup  :options="{autoPanPadding:[50, 55]}">
                  <div class="popup-content">
                    <div class="popup-title" >{{r.name}}</div>
                    <img class="popup-preview" v-if="r.preview != null" :src="getRealEstateAssetsPreview(r)" :style="{'background-image' :  'url(' + getRealEstateAssetsPreview(r)+')','background-position': 'center','background-repeat': 'no-repeat','background-size': 'contain'}">
                  </div>
                </l-popup>
                <l-icon :icon-url="currentAsset?.id===r.id ? markerIconOnUrl:markerIconOffUrl" :icon-size="[15, 15]" />
                <!--l-circle-marker v-for="r in realEstateAssets"  :key="r.id" :lat-lng="[r.gps_coordinates.latitude, r.gps_coordinates.longitude]"  @click="markerClick(r)" :radius="20" :fill="true" color="#FF0000" fillColor="#00FF00" >
                  <l-circle-marker v-if="currentAsset?.id===r.id" :lat-lng="[r.gps_coordinates.latitude, r.gps_coordinates.longitude]" :radius="10" :fill="true" color="#FF0000" fillColor="#FF0000" :fillOpacity="1" />
                </l-circle-marker-->
              </l-marker>
          </l-map>

          <welcome-popup v-if="showWelcome" v-on:close="hideWelcome()"></welcome-popup>
          <div v-if="(segmentValue === 'list') " class="panel-list" key="list">
            <div v-if="realEstateAssets.length == 0" class="no-asset-list">
              <h1>{{$t('map.assets.empty')}}</h1>
            </div>
            <ion-list v-else class="asset-list">
              <ion-item class="asset-list-item" v-for="r  in realEstateAssets" :key="r.id">
                <div>
                  <div class="asset-list-item-top">
                    <div class="asset-list-preview" v-if="r.preview !== null" :style="{'background-image' :  'url(' + getRealEstateAssetsPreview(r)+')','background-position': 'bottom','background-repeat': 'no-repeat','background-size': 'cover'}"></div>
                    <div class="asset-list-content">
                      <div class="asset-list-item-title" >{{r.name}}</div>
                      <div class="asset-list-item-subtitle" >{{r.adress.city+' '+r.adress.postal_code}}</div>
                      <p class="asset-list-item-message" >{{$filters.truncate(r.description[$i18n.locale],170,'...')}}</p>
                      
                    </div>
                    <ion-button fill="clear" class="asset-list-detail-btn" :router-link="{'name':'RealEstateAssetDetail', 'params':{ 'id' :parseInt(r.id)}}"></ion-button>
                  </div>
                  <div v-if="r.metadatas.length != 0" class="data-panel asset-list-data-key">
                      <data-key class="data-key" :data="processRealEstateAssetsMetadata(r, ['RentalArea', 'TenantCount', 'BuyingDate'])"></data-key>
                  </div>
                </div>
              </ion-item>
            </ion-list>
          </div>
          <div v-if="(segmentValue === 'map') " class="panel-container" key="map">
            <div v-if="realEstateAssets.length == 0" class="no-asset-map">
              <h1>{{$t('map.assets.empty')}}</h1>
            </div>
            <template v-else>
              <ion-slides :key="realEstateAssets" ref="slides" class="slides-container" :options="slideOpts" @ionSlideDidChange="slideDidChange" @ionSlidesDidLoad="syncSlide(0)" >
                <ion-slide>
                  <ion-button class="slide-btn" :class="currentAsset===null ? 'active':'' " fill="outline"  @click="onClickAllAssets()"> {{$t('map.allAssets')}}</ion-button>
                </ion-slide>
                
                <ion-slide v-for="r  in realEstateAssets" :key="r.id">
                  <ion-button class="slide-btn" :class="currentAsset?.id===r.id ? 'active':'' " fill="outline"  @click="SelectRealEstateAsset(r)">{{r.name}}</ion-button>
                </ion-slide>
            
              </ion-slides>
              <div class="panel">
                <div v-if="currentAsset === null" class="all-assets-panel">
                    <h3>{{$t('components.dataKey.sectionTitle2')}}</h3>
                    <data-key  :data="allAssetsData"></data-key>
                </div>
                <div class="asset-panel" v-else>
                  <!-- <div class="asset-preview" v-if="currentAssetPreview" :style="{'background-image' :  'url(' + currentAssetPreview+')','background-position': 'bottom','background-repeat': 'no-repeat','background-size': 'cover'}"></div> -->
                  <div class="asset-content">
                    <h3>{{currentAsset.name}}</h3>
                    <!-- <p>{{currentAsset.description[$i18n.locale]}}</p> -->
                    <div class="data-panel">
                      <data-key class="data-key" :data="currentAssetMetadata"></data-key>
                    </div>
                    <ion-button fill="clear" class="detail-btn" :router-link="{'name':'RealEstateAssetDetail', 'params':{ 'id' :parseInt(currentAsset.id)}}"></ion-button>
                  </div>
                </div>
              </div>
            </template>
          </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonPage, IonButton, IonLabel, IonSlides, IonSlide, loadingController, IonSegment, IonSegmentButton } from '@ionic/vue';
import { defineComponent } from 'vue';

import { mapState, mapActions } from 'vuex'

import WelcomePopup from '../components/WelcomePopup.vue';

import  DataKey from '../components/DataKey.vue';

import  LangBtn from '../components/LangBtn.vue';
import  DashboardBtn from '../components/DashboardBtn.vue';

//import  LMap from '../components/map/LMap.vue';
//import  LMarker from '../components/map/LMarker.vue';

import {combileRealEstateAssetsData} from '../_helpers/combineRealEstateAssetsData';

import {cloneDeep as _cloneDeep, filter as _filter, sortBy as _sortBy  } from 'lodash';

import {
  LMap,
  LIcon,
  LTileLayer,
  //LControlZoom,
  //LControlAttribution,
  LMarker,
  //LControlLayers,
  //LTooltip,
  LPopup,
  //LPolyline,
  //LPolygon,
  //LRectangle,
  //LCircleMarker,
} from "@vue-leaflet/vue-leaflet";

//import LMarkerClusterGroup from '../components/map/LMarkerClusterGroup.vue';


import "leaflet/dist/leaflet.css";
import config from '@/_services/config';
//import { bounds } from 'leaflet';
//import { Map } from 'leaflet';



export default defineComponent({
  name: 'Map',
  components: {
    WelcomePopup,
    IonContent,
    //IonRefresher, IonRefresherContent,
    IonPage,
    IonButton,
    IonLabel,
    IonSlides, IonSlide,
    IonSegment, IonSegmentButton,
    LangBtn,
    DashboardBtn,

    LMap,
    LMarker,
    LPopup,
    LIcon,
    //LCircleMarker,
    LTileLayer,
    //LControlZoom,
    //LControlAttribution,

    //LMarkerClusterGroup,
    
    DataKey
  },
  props:
  {
    display:{
      type: String,
      required: false,
      //default: 'map',
      //validator: function (value: string) {
        // La valeur passée doit être l'une de ces chaines de caractères
      //  return ['map', 'list'].indexOf(value) !== -1
      //}

    }
  },
  data() {
    const slideOpts = {
      initialSlide: 0,
      pager:false,
      speed: 200,
      slidesPerView: 'auto',
      spaceBetween: 50,
      centeredSlides: true,
      
      };
    //console.log(this);
    const currentAsset: any = null;
    const showWelcome = false;
    const markerState = false;
    const loading: HTMLIonLoadingElement|any = null;
    
    const markerIconOnUrl = 'assets/img/MarkerMap_ON_R2.png';
    const markerIconOffUrl = 'assets/img/MarkerMap_OFF_R2.png';
    return {
      currentAsset,
      segmentValue:'map',
      //segmentValue: this.$props['display'],
      slideOpts,
      showWelcome,
      markerState,
      markerIconOnUrl,
      markerIconOffUrl,
      loading,
      mapCenter :[47.41322, -1.219482],
      mapZoom : 15,
      
    };
  },
  setup() {  

    return { 
      //segmentValue:$props['display'],
      messages : 10
     };
  },
  computed: {
    ...mapState<any>({
        user: state =>  state.authentication.user,
        realEstateAssets: state =>  state.authentication.realEstateAssets,
        currentAssetPreview: function(){
          if(this.currentAsset?.preview)
          {
            if(this.currentAsset.preview.generated_conversions?.thumb512)
              return config.assetsUrl+this.currentAsset.preview.generated_conversions?.thumb512;

            return config.assetsUrl+this.currentAsset.preview.public_url;
          }
          return null;
        },
        allAssetsData: function(){
          return this.allAssetsDataFunction();
        },
        currentAssetMetadata :function()
        {
          return this.processRealEstateAssetsMetadata(this.currentAsset);
        }
    })
  },
  watch:{
    currentAsset:function()
    {
      if(this.segmentValue === 'list')
        return;

      console.log("currentAsset change",this.currentAsset );
      this.syncMap();
      this.syncSlide();

      
    }
  },
  methods: {
    ...mapActions('authentication', {
        getUser: 'getUser',
        getRealEstateAssets: 'getRealEstateAssets',
    }),
    hideWelcome()
    {
      this.showWelcome = false;
    },
    doRefresh(event: CustomEvent)
    {
      if(event.target !== null)
      {
        /*
        //this.getUser().finally(() => (event.target as unknown as typeof IonRefresher).complete());  
        Promise.all([this.getUser(),this.getRealEstateAssets()]).then(values => {
          //console.log(values);
          (event.target as unknown as typeof IonRefresher).complete();  
        }).catch(reason => {
          //console.log(reason);
          (event.target as unknown as typeof IonRefresher).complete();
        });
        */
      }
    },
    markerClick($item)
    {
      //console.log('markerClick', this.$refs.map);
      this.currentAsset = $item;
      
    },
    onClickAllAssets()
    {
      this.currentAsset = null;
    },
    SelectRealEstateAsset( $item)
    {
      //console.log('SelectRealEstateAsset', $item);
      this.currentAsset = $item;
    },
    async presentLoading() {
      
      if(this.loading !== null)
        return;

      this.loading = await loadingController
        .create({
          cssClass: 'app-loading',
          message: this.$t('loading.pleaseWait'),
          //duration: this.timeout,
        });

      await this.loading.present();

    },
    dismissLoading()
    {
      if(this.loading !== null)
      {
        this.loading.dismiss();
        this.loading = null;
      }
    },
    async slideDidChange()
    {
      console.log("slideDidChange 1"); 
      console.log("slideDidChange 2", (this.$refs.slides as any).$el.getActiveIndex());
      
      (this.$refs.slides as any).$el.getActiveIndex().then( (i) => {
        
        if(i==0)
        {
          this.currentAsset = null;
        }else
        {
          this.currentAsset = this.realEstateAssets[i-1];
        }
        
      });
      
    },
   
    syncSlide(speed?: number | undefined)
    {
      console.log("sync Slide");
       
      if(this.currentAsset !== null)
      {
        const index = this.realEstateAssets.findIndex((i) => i.id==this.currentAsset.id);
        (this.$refs.slides as any).$el.slideTo(index+1,speed);

      }
      else
      {
          //console.log("zooming to all point");
          (this.$refs.slides as any).$el.slideTo(0,speed);
      }
    },
    syncMap()
    {
      if(this.currentAsset !== null)
      {
        //console.log("zooming to",this.currentAsset.gps_coordinates );
        //console.log("map",(this.$refs.map as typeof LMap).leafletObject );
        (this.$refs.map as typeof LMap).leafletObject.setView([this.currentAsset.gps_coordinates.latitude,this.currentAsset.gps_coordinates.longitude],16);
        setTimeout( () => {this.syncPopup();},500);
      }
      else
      {
          this.syncPopup();
          this.fitToAll();
      }
    },
    syncPopup()
    {
      if(this.currentAsset != null)
      {
        console.log(this.$refs);
        
        const p = (this.$refs["markerPopup_"+this.currentAsset.id] as typeof LPopup).leafletObject;
        const m = (this.$refs.map as typeof LMap).leafletObject;
        console.log(p);
        console.log(m);
        p.openPopup();
        //m.openPopup(p);
      }else
      {
        
        const m = (this.$refs.map as typeof LMap).leafletObject;
        m.closePopup();
      }
    },
    mapReady()
    {
      //console.log("leaflet map is ready");
      (this.$refs.map as typeof LMap).leafletObject.attributionControl.remove();
      (this.$refs.map as typeof LMap).leafletObject.zoomControl.remove();
      //this.$nextTick( () =>
      //(this.$refs.map as typeof LMap).leafletObject.invalidateSize());
      setTimeout(()=> 
      {
        
         (this.$refs.map as typeof LMap).leafletObject.invalidateSize();
         //console.log((this.$refs.map as typeof LMap).leafletObject.zoomControl);
         //(this.$refs.map as typeof LMap).leafletObject.attributionControl.remove();
         //(this.$refs.map as typeof LMap).leafletObject.zoomControl.remove();
         //this.$nextTick( () =>this.fitToAll());
         this.fitToAll();
      }, 500);
      
    },
    fitToAll()
    {
      const b: Array<any> = [];
      for(const element of this.realEstateAssets)
      {
        b.push([element.gps_coordinates.latitude, element.gps_coordinates.longitude]);
      }

      console.log("bounds" , b);
      //console.log("map-bounds" , this.$refs.mapBounds);
      //console.log("map-bounds" , this.$refs.mapBounds as HTMLElement);
      //console.log("map-bounds" , (this.$refs.mapBounds as any).getClientRects());
      //console.log("map-bounds" , (this.$refs.mapBounds as any).getBoundingClientRect());

      const mapBounds = (this.$refs.mapBounds as any).getBoundingClientRect();
      (this.$refs.map as typeof LMap).leafletObject.fitBounds(b,{padding: [mapBounds.left, mapBounds.top]});

      
  
    },
    handleCmdMouseDown()
    {
      //console.log('handle Cmd Mouse Down');
    },
    handleCmdMouseUp()
    {
      //console.log('handle Cmd Mouse Up');
    },
    allAssetsDataFunction()
    {
          
          const r = combileRealEstateAssetsData(this.realEstateAssets,this.$i18n.locale );
          r.push( {
            combineType: "none",
            combineFormat: null,
            key: "RealEstateAssetsCount",
            type: "number",
            order: 0,
            name: {en: "Assets", fr: "Actifs"},
            unit: null,
            // eslint-disable-next-line @typescript-eslint/camelcase
            raw_value: this.realEstateAssets.length,
            value: {
              en:this.realEstateAssets.length,
              fr:this.realEstateAssets.length,
            },
            values: [],
          });
          return _sortBy(r,[function(o) { return o.order; }]) ;
    },
    getRealEstateAssetsPreview(r)
    {
      if(r.preview)
          {
            if(r.preview.generated_conversions?.thumb512)
              return config.assetsUrl+r.preview.generated_conversions?.thumb512;

            return config.assetsUrl+r.preview.public_url;
          }
      return null;
    },
    processRealEstateAssetsMetadata(r, useFilter: string[]|null = null)
    {
      if(r?.metadatas)
          {
            let m = _cloneDeep(r.metadatas);
            const c = _filter(r.metadatas, function(o){return o.key == 'Occupant_Tenant'}).length;
            m.push( {
              combineType: "sum",
              key: "TenantCount",
              type: "number",
              order: 20,
              name: {en: "Tenant count", fr: "Nombre de locataires"},
              unit: null,
              // eslint-disable-next-line @typescript-eslint/camelcase
              raw_value: c,
              value: {
                en:c,
                fr:c,
              },
              values: [],
            });

            if(useFilter != null)
            {
              m =  _filter(m, function(o){return useFilter.includes(o.key) });
            }
            //console.log('currentAssetMetadata',m);
            return _sortBy(m,[function(o) { return o.order; }]) ;
          }
          return null;
    }
  },
  async ionViewWillEnter()
  {
    //console.log('display', this.display);
    //console.log('display router', this.$route);
    //if(this.display != null)
    if( this.$route.params.display != null)
    {
      this.segmentValue = this.$route.params.display as string;
    }
  },
  async ionViewDidEnter() {
    //console.log('Home page did enter');
    //document.title='Rori Partners - Map';

    await this.presentLoading();
    console.log('ionViewDidEnter enter'); 
    //(this.$refs.slides as any).$el.update();
    //this.syncSlide(0);
     
    Promise.all([this.getUser(), this.getRealEstateAssets()]).finally(() => {
      
      this.dismissLoading(); 
      //console.log("reset slide");
      //this.$nextTick( () => { (this.$refs.slides as any).$el.update(); this.syncSlide();});
    });
  },
  
});
</script>

<style scoped>
#container {
  
  
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  min-height: 550px;
  display: flex;
  flex-direction: column;
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
  
}


.map
{
  
  width: 100%;
  flex-grow: 10;
  pointer-events: all;
  position: relative;
}
.map-bounds-container
{
  margin: auto;
  padding: 100px;
  height: 100%;
  min-height: 100px;
  min-width: 100px;
  max-width: var(--container-responsive-max-width);
  
}
.map-bounds
{
  width: 100%;
  height: 100%;
  z-index: 10000;
}

.popup-content
{
  text-align: center;
  
}


.popup-title
{
  font-size: 1.2em;
  text-align: center;
  font-weight: 600;
}

.popup-preview
{
  flex: none;
  width: auto;
  max-height: 85px;

  margin: auto;
  background-color: white;
}

.cmd-btn
{
  position: absolute;
  pointer-events: auto;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 60px;
  z-index: 1000;
}

.active
{
  font-weight: 700;
  --background: var(--ion-color-primary)  !important;
  color: white  !important;
  transform: scale(1.3);
  transition: all;
}

.lang-btn
{
  position: absolute;
  width: 60px;
  height: 60px;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  text-align: center;
}

.detail-btn
{
  display: block;
  margin: 20px auto;
  width: 60px;
  height: 60px;
  background: url( '../../public/assets/img/PICT_FlecheDroiteCercle_R.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.dashboard-btn
{
  position: absolute;
  width: 40px;
  height: 40px;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  text-align: center;
}

.segment-control
{
  position: absolute;
  width: 200px;
  height: 40px;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.panel-list
{
  position: absolute;
  width: 100%;
  top: 0px;
  bottom: 0px;
  z-index: 500;
  background-color:var(--ion-color-light);
}

.panel-container
{
  position: relative;
  height: 300px;
  min-height: 300px;
  max-height: 300px;
  z-index: 500;
  background-color:var(--ion-color-light);
}
.panel
{
  max-width: var(--container-responsive-max-width);
  
  margin: auto;
  overflow: auto;
  height: 100%;
  background-color:white;
}

.slides-container
{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px;
  transform: translateY(-100%);
}
.slide-btn
{
  height: 30px;
  border: 0px solid var(--ion-color-primary);
  border-radius: 15px;
  --border-radius:15px;
  --background: white;
  color: var(--ion-color-primary);
  font-size: 0.7em;
  transition: background-color 0.2s, transform 0.2s;
}

ion-slide
{
  width: unset;
  
}

.asset-panel
{
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  height: 100%;
}
.all-assets-panel
{
  margin: 30px 10px;
}

.all-assets-panel h3
{
  margin-left: 20px;
}

.asset-preview
{
  flex: none;
  width:100px;
  background-color: lightgray;
}

.asset-content
{
  margin: 10px 20px;
  display: flex;
  flex-direction: column;
  width: 100%;

}

.asset-content p
{
  overflow: auto;
}
.data-panel
{
  overflow: auto;
  height: 100%;
}

.asset-list-data-key
{
  max-height: 135px;
}

.asset-list-data-key > div > ion-list
{
  padding-bottom: 0px;
}

.asset-list
{
  background-color: rgb(240, 240, 240);
  
  margin: auto;
  padding-top: 100px;

  width: 100%;
  height: 100%;

  overflow: auto;
}

.asset-list-item
{

  margin: 10px auto ;
  max-width: 500px;
  

  box-shadow: gray;

  --padding-start : 0px;
  --padding-end : 0px;
  --inner-padding-end : 0px;
}

.asset-list-item-top
{
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 180px;
  padding-right: 16px;
}

.asset-list-preview
{
  flex: none;
  width:120px;
  background-color: lightgray;
}

.asset-list-content
{
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
  margin-right: 10px;

  overflow: hidden;
}

.asset-list-item-title
{
  font-size: 1.2em;
  font-weight: 600;
}
.asset-list-item-subtitle
{
  font-size: 1em;
  margin-bottom: 10px;
  
}

.asset-list-item-message
{
  text-align: justify;
}


.asset-list-detail-btn
{
  flex: none;
  margin: auto;
  width: 40px;
  height: 40px;
  background: url( '../../public/assets/img/PICT_FlecheDroiteCercle_R.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

}


.segment-control
{
  background: var(--ion-color-primary);
  height: 30px;
  border-radius:15px;
}
.segment-btn
{
  min-height: 26px;
  --indicator-color : transparent;

  
  --background: white;
  --background-checked : var(--ion-color-primary);
  

  --color:var(--ion-color-primary);
  --color-checked	: white;
  --color-hover: lightgray;

  margin: 2px;
}

.segment-btn-map
{
  
  --border-radius: 13px 0px 0px 13px;
}

.segment-btn-list
{
  --border-radius: 0px 13px 13px 0px;
}
.segment-button-checked
{

}


.segment-button-indicator
{
  display: none;
}

.no-asset-list
{
  margin-top: 100px;
  height: 100%;
  width: 100%;
  display: flex;
}
.no-asset-list h1
{
  margin: auto;
}

.no-asset-map
{
  height: 100%;
  width: 100%;
  display: flex;
}
.no-asset-map h1
{
  margin: auto;
}


@media(min-width:540px) 
{
  .cmd-btn
  {
    height: 60px;
  }

  .lang-btn
  {
    width: 60px;
    height: 60px;
  }

  .dashboard-btn
  {
    width: 40px;
    height: 40px;
  }

  .asset-preview
  {
    width:200px;
  }
}

@media(min-width:540px) and  (min-height:700px)
{
  .popup-content
  {
    text-align: center;
  }

  .popup-preview
  {
    flex: none;
    width: auto;
    max-height: 135px;

    margin: auto;
    background-color: white;
  }
}

</style>