<template>
  <ion-header>
    <ion-toolbar>
        <ion-title>{{message.title}}</ion-title>
        <ion-buttons slot="end">
            <ion-button color="primary" @click="dismissModal()">{{$t('components.documentModal.closeBtn')}}</ion-button>
        </ion-buttons>      
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-label  class="message">{{message.message}}</ion-label>
  </ion-content>
</template>

<script>
import { IonContent, IonHeader, IonTitle, IonToolbar, IonLabel,IonButton, IonButtons,modalController } from '@ionic/vue';
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'UserMessageModal',
  components: { 
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonLabel,
    IonButton,
    IonButtons,
    
    },
  props: {
    message:{type:Object}
  },
  data() {
    return {
      content: 'Content',
    }
  },
  methods:
  {
    dismissModal()
    {
        modalController.dismiss();
    },
  }
  
});
</script>

<style scoped>



</style>