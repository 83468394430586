
import {  IonItem,IonLabel } from '@ionic/vue';
import { defineComponent } from 'vue';

import moment from 'moment';
import config from '@/_services/config';
import { Browser } from '@capacitor/core';

export default defineComponent({
  name: 'document-list-item',
  components: {
    IonItem,
    IonLabel
  },
  props:
  {
    filename: String,
    date: String,
    src: String,
  },
  setup() {  
    
    return {  };
  },
  methods: {
    openDoc()
    {
      console.log("opening",this.src);
      Browser.open({ url: config.assetsUrl+this.src });
    }
  },
  computed: {
    computeddate(): string {
    
      this.$i18n.locale;
      return moment(this.date).format('L') ;
    }
  }
});
