<template>
<ion-card class="message-panel">
  <ion-card-header>
    <ion-card-title>{{message.title}}</ion-card-title>
    <!--ion-card-subtitle>{{message.subtitle}}</ion-card-subtitle-->
  </ion-card-header>

  <ion-card-content>
    {{$filters.truncate(message.message,50,'...')}}
  </ion-card-content> 
</ion-card>
  
</template>

<script lang="ts">
import {  IonCard, IonCardTitle, IonCardHeader, IonCardContent } from '@ionic/vue';
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'user-message-item',
  props: {
    message: {type: Object}
  },
  components: {
    IonCard,
    IonCardTitle,
    IonCardHeader,
    IonCardContent
  },
  setup() {  
    return {  };
  },
  methods: {
    
  },
});
</script>

<style scoped>
.message-panel
{
  width: 250px;
  height:150px;
  margin: 5px 10px;
}


</style>