
import {  IonItem, IonLabel} from '@ionic/vue';
import { defineComponent } from 'vue';

import moment from 'moment';
import numeral from 'numeral';

export default defineComponent({
  name: 'data-key-item',
  components: {
    IonItem,
    IonLabel
  },
  props:
  {
    type: String,
    label: String,
    value: String,
  },
  setup() {  
    return {  };
  },
  computed: {
    formattedValue(): any
    {
      this.$i18n.locale;
      switch(this.type)
      {
        
        case 'Rent' :
        case 'Investment' :
        case 'Distribution' :
        case 'BuyingPrice' : 
        case 'RentalArea' : return numeral(Number(this.value)).format('0[.]00a');
        case 'Walt' : return numeral(Number(this.value)).format('0[.]00');
        case 'BuyingDate' : return moment(this.value).format('L');
        
      }
      return this.value;
    }
  },
  methods: {
    
  },
});
