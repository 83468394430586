
import {  IonCard, IonCardTitle, IonCardHeader, IonCardContent } from '@ionic/vue';
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'user-message-item',
  props: {
    message: {type: Object}
  },
  components: {
    IonCard,
    IonCardTitle,
    IonCardHeader,
    IonCardContent
  },
  setup() {  
    return {  };
  },
  methods: {
    
  },
});
