<template>
    <ion-slide class="slide">
      <div class="content" v-on:touchmove.stop v-on:pointermove.stop >
        <div ref="viewer360" id="viewer360" class="viewer360" v-on:resize="viewerResize"></div>
        <div ref="loading" class="loading"></div>
        <div ref="next" class="control-btn control-btn-left"  @click="prev()" >
          <ion-icon class="icon" :icon="chevronBackOutline"></ion-icon>
        </div>
        <div ref="prev" class="control-btn control-btn-right"  @click="next()" >
          <ion-icon class="icon" :icon="chevronForwardOutline"></ion-icon>
        </div>
      </div>
    </ion-slide>
</template>

<script lang="ts">
import {  
  IonSlide,
  } from '@ionic/vue';

import { defineComponent } from 'vue';

import { ImagePanorama,Viewer } from 'panolens'; 

import { IonIcon } from '@ionic/vue';

import { chevronBackOutline, chevronForwardOutline} from 'ionicons/icons';

export default defineComponent({
  name: 'lightbox-item-360',
  components: {
     IonSlide,
     IonIcon

  },
  props: {
    active : {type:Boolean, default:true},
    type: {type:String},
    url: {type:String},
    title: {type:String},
    message: {type:String}
  },
  emits: ['prev', 'next'],
  data()
  {
    const viewer360: any = null;
    const panorama: any = null;
    
    return {viewer360, panorama , chevronBackOutline, chevronForwardOutline};
  },
  mounted()
  {
    console.log("lightbox360 mounted");

    this.loadPanorama();

  },
  unmounted()
  {
    console.log("lightbox360 unmounted");

    this.disposePanorama();
    
  },
  methods: {
    stop(ev)
    {
      console.log("stop event",ev);
    },
    loadPanorama()
    {
      
      if(this.viewer360 == undefined)
            {
                //console.log($("#viewer360"));
                this.viewer360 = new Viewer( { container: this.$refs.viewer360 as HTMLElement } );

            }

            // Keep one panorama
            if ( this.panorama ) { return; }

            const image = new Image();

            image.onload = () => {
              console.log("image is loaded");

              // Dynamically generate panorama
              this.panorama = new ImagePanorama( image);
              this.viewer360.add( this.panorama );

              this.viewerResize();

            };
            


            image.crossOrigin = "anonymous";
            image.src = this.url as string;
            
              //panorama = new PANOLENS.VideoPanorama( src, { autoplay: true } );

              //this.panorama.addEventListener( 'progress', function ( event ) {
              //const progress = event.progress.loaded / event.progress.total * 100;
              //console.log("Loading360 => " + progress);
              
              //if ( progress === 100 ) {
              //  console.log("Loading360 DONE => " + progress);
              //}

            //});
            
    },
    disposePanorama()
    {
      this.panorama.dispose();
      this.viewer360.remove( this.panorama );
      this.panorama = null;
    },
    viewerResize()
    {
      if(this.viewer360 != undefined)
      {
        const bounds = (this.$refs.viewer360 as any).getBoundingClientRect();
        //const bounds = (this.$refs.viewer360 as any).getClientRects();
        
        this.viewer360.onWindowResize(bounds.width, bounds.height);
      }
    },
    next()
    {
      this.$emit('next');
    },
    prev()
    {
      this.$emit('prev');
    }
  }
});








</script>

<style scoped>


ion-slide
{
  width: unset;
}

.content{
    width: 100%;
    height: 100%;
    padding-bottom: 40px;
    position: relative;

    
}

.viewer360{
  margin: auto;
    width: 100%;
    height: 100%;
}

.control-btn
{
  position: absolute;
  width: 50px;
  height: 100px;

  top: 50%;
  transform: translateY(-50%);

  color: var(--ion-color-primary);
  font-size: 50px;

}

.control-btn-left
{
  left: 0px;
}

.control-btn-right
{
  right: 0px;
}
.icon{
  width: 100%;
  height: 100%;
  text-align: center;
  align-self: center;
}


</style>