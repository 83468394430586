
import {  
  IonSlides,
  } from '@ionic/vue';

import { defineComponent } from 'vue';
import LightboxItem from './LightboxItem.vue';
import LightboxItem360 from './LightboxItem360.vue';


export default defineComponent({
  name: 'lightbox',
  components: {
    IonSlides,
    LightboxItem,
    LightboxItem360,

  },
  props: {
    startIndex: Number,
    items: {type:Array}
  },
  
  data()
  {
    const currentIndex = this.startIndex;
    const slideOpts = {
      initialSlide: this.startIndex,
      zoom: true,
      speed: 400,
      slidesPerView: 1,
      spaceBetween: 30,
      centeredSlides: true,
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
      
    };

    console.log('slideOpts', slideOpts);

    return {slideOpts,currentIndex,}
  },
  setup() {  
    

    return { 
      //slideOpts,
      //messages : 5
     };
  },
  methods: {
   prev()
   {
      (this.$refs.mySwiper as any).$el.slidePrev();
   },
   next()
   {
      (this.$refs.mySwiper as any).$el.slideNext();
   },
   async slideDidChange()
    {
      //console.log("slideDidChange 1");
      //const index = await (this.$refs.slides as typeof IonSlides ).getActiveIndex();
      
      //console.log("slideDidChange 2", (this.$refs.mySwiper as any).$el.getActiveIndex());
      
      (this.$refs.mySwiper as any).$el.getActiveIndex().then( (i) => {
        //console.log("slideDidChange 3",i);
        
        this.currentIndex = i;
        
        
      });
      
    },
  },
  mounted()
  {
    //mySwiper.reInit(true)
    console.log("mounted lightbox", (this.$refs.mySwiper as any).$el);
    
    //this.$nextTick( () => { (this.$refs.mySwiper as any).$el.update()});
    setTimeout( () => { (this.$refs.mySwiper as any).$el.update()}, 500 );
    //this.$nextTick( () => { (this.$refs.mySwiper as any).$el.pagination.update()});
    //this.$nextTick().then(() =>this.$nextTick(  () => { (this.$refs.mySwiper as any).$el.update()}));
  },
  
});
