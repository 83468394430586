<template>
<div class="panel">
      <!-- <data-key-item  v-for="d in data" :key="d" :type="d.key" :label="d.name[$i18n.locale]" :value="d.type=='number' ? $filters.formatNumber(d.raw_value,'0,0[.]00'): d.raw_value" @click="show(d.key)"></data-key-item> -->
      <data-key-item  v-for="d in data" :key="d" :type="d.key" :label="d.name[$i18n.locale]" :value="d.raw_value" @click="show(d.key)"></data-key-item>
</div>
  
</template>

<script lang="ts">

import { EmailPlugin } from '@/_helpers/EmailPlugin';
import { defineComponent } from 'vue';
import DataKeyItem from './DataKeyIconItem.vue';


export default defineComponent({
  name: 'data-key-icon',
  components: {
    
    
    DataKeyItem
    
  },
  props: {
    data: {type:Array}
  },
  emits: ['show'],
  methods: {
    
    show(key: string)
    {
      //console.log("[DataKey Icon] show",key);
      this.$emit('show',key)
    }
  },
});
</script>

<style scoped>

.panel
{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

</style>