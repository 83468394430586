<template>
<div>
  <div v-if="data!=null">
      <h1 v-if="'name' in data.tags">{{data.tags['name']}}</h1>
      <h3 v-if="'amenity' in data.tags">{{getTagTranslation('amenity',data.tags['amenity'])}}</h3>
      <h3 v-if="'shop' in data.tags">{{getTagTranslation('shop',data.tags['shop'])}}</h3>
      <h3 v-if="'leisure' in data.tags">{{getTagTranslation('leisure',data.tags['leisure'])}}</h3>
      <h3 v-if="'tourism' in data.tags">{{getTagTranslation('tourism',data.tags['tourism'])}}</h3>
      <h3 v-if="'office' in data.tags">{{getTagTranslation('office',data.tags['office'])}}</h3>
      
      
      
      <ion-list>
        <ion-item v-if="'cuisine' in data.tags" class="infoPaneItem">
          <ion-label class="label label-name">{{$t('Cuisine')}}</ion-label>
          <ion-note class="label label-value" slot="end">{{getTagTranslation('cuisine',data.tags['cuisine'])}}</ion-note>
        </ion-item>
        <ion-item v-if="'sport' in data.tags" class="infoPaneItem">
          <ion-label class="label label-name">{{$t('Sport')}}</ion-label>
          <ion-note class="label label-value" slot="end">{{getTagTranslation('sport',data.tags['sport'])}}</ion-note>
        </ion-item>
        
        <ion-item v-if="'diet:vegan' in data.tags" class="infoPaneItem">
          <ion-label class="label label-name">{{getTagTranslation('diet:vegan')}}</ion-label>
          <ion-note class="label label-value" slot="end">{{$t(data.tags['diet:vegan'])}}</ion-note>
        </ion-item>
        <ion-item v-if="'capacity' in data.tags" class="infoPaneItem">
          <ion-label class="label label-name">{{getTagTranslation('capacity')}}</ion-label>
          <ion-note class="label label-value" slot="end">{{$t(data.tags['capacity'])}}</ion-note>
        </ion-item>
        <ion-item v-if="'outdoor_seating' in data.tags" class="infoPaneItem">
          <ion-label class="label label-name">{{$t('outdoorseating')}}</ion-label>
          <ion-note class="label label-value" slot="end">{{$t(data.tags['outdoor_seating'])}}</ion-note>
        </ion-item>
        <ion-item v-if="'takeaway' in data.tags" class="infoPaneItem">
          <ion-label class="label label-name">{{$t('takeaway')}}</ion-label>
          <ion-note class="label label-value" slot="end">{{$t(data.tags['takeaway'])}}</ion-note>
        </ion-item>
        <ion-item v-if="'wheelchair' in data.tags" class="infoPaneItem">
          <ion-label class="label label-name">{{getTagTranslation('wheelchair')}}</ion-label>
          <ion-note class="label label-value" slot="end">{{$t(data.tags['wheelchair'])}}</ion-note>
        </ion-item>
        <ion-item v-if="'opening_hours' in data.tags" class="infoPaneItem">
          <ion-label class="label label-name">{{getTagTranslation('opening_hours')}}</ion-label>
          <ion-note class="label label-value" slot="end">{{$t(data.tags['opening_hours'])}}</ion-note>
        </ion-item>
        <ion-item v-if="'phone' in data.tags" class="infoPaneItem">
          <ion-label class="label label-name">{{getTagTranslation('phone')}}</ion-label>
          <ion-note class="label label-value" slot="end">{{data.tags['phone']}}</ion-note>
        </ion-item>
        <ion-item v-if="'website' in data.tags" class="infoPaneItem">
          <ion-label class="label label-name">{{getTagTranslation('website')}}</ion-label>
          <ion-note class="label label-value" slot="end">{{data.tags['website']}}</ion-note>
        </ion-item>

      </ion-list>
      <div v-if="debug">
        <h1>Debug Info</h1>
        <ion-list>
          <template v-for="(l,k) in data.tags" :key="e+k">
            <ion-item class="infoPaneItem">
              <ion-label class="label label-name">{{k}}</ion-label>
              <ion-note class="label label-value" slot="end">{{l}}</ion-note>
            </ion-item>
          </template>
        </ion-list>
      </div>
    </div>  
    <div v-else>{{$t('components.dataKey.empty')}}</div>  
</div>
  
</template>

<script lang="ts">

import {  
  IonList,
  IonItem,
  IonLabel,
  IonNote,

 } from '@ionic/vue';
 
import { defineComponent } from 'vue';



export default defineComponent({
  name: 'leaflet-poi-detail',
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonNote,
  
  },
  props: {
    data: {type:Object},
    debug: {type:Boolean, default : false},
  },
  setup() {  
    return { keys :5 };
  },
  computed: {
    currentLanguage(): any
    {
      return this.$i18n.locale;
    },
    currentLanguageTranslation()
    {
      return require(`../../locales/osm/tags/${this.currentLanguage}.json`);
      //return this.$i18n.locale;
    }
  },
  methods: {
    getTagTranslation(tag,v)
    {
      const loc = this.currentLanguageTranslation;
      //console.log(loc);
      const tv = `tag:${tag}=${v}`;
      const t = `tag:${tag}`;
      if(tv in loc)
      {
        return loc[tv].message;
      }
      
      if(t in loc)
      {
        return loc[t].message;
      }

      return tag;
    }
  },
});
</script>

<style scoped>

.no-data-list
{
  margin: auto;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  color: darkgray;

}
.infoPaneItem
{
  --padding-start: 0px;
  --inner-padding-end: 0px;
  --min-height: 30px;
}
.label
{
  padding: 0;
  font-size: 0.8em;
}

.label-name
{
  
  font-weight: 700;
  white-space: normal;
  max-width: 55%;
}

.label-value
{
  
  text-align: end;
  font-style: italic;
  margin-inline-start: 5px;
  white-space: normal;
  max-width: 45%;
}


</style>