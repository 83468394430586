<template>
  <ion-slides :key="messages" ref="mySwiper" pager="true" :options="slideOpts">
    <ion-slide v-if="messages.length ==0" :key="messages.length">    
      <ion-card class="no-messages-card">
        <div class="no-messages-text">
          {{$t('components.userMessages.noMessages')}}
        </div> 
      </ion-card>
      
    </ion-slide>
    <ion-slide v-else v-for="m in messages" :key="m">
      <user-message-item :message="m" @click="showMessage(m)" ></user-message-item>
    </ion-slide>
  </ion-slides>

</template>

<script lang="ts">
import {  
  IonSlides,
  IonSlide,
  IonCard,
  modalController
  } from '@ionic/vue';

import { defineComponent } from 'vue';

import UserMessageItem from './UserMessageItem.vue';

import UserMessageModal from './UserMessageModal.vue'


//import {UserMessageInterface} from '../_interfaces/UserMessage';

export default defineComponent({
  name: 'user-messages',
  components: {
    IonSlides, IonSlide,
    UserMessageItem,
    IonCard,

  },
  props: {
    messages: {type:Array}
  },
  data()
  {
    const slideOpts = {
      initialSlide: 0,
      speed: 400,
      slidesPerView: 'auto',
      spaceBetween: 30,
      centeredSlides: true,
      
    };


    return {slideOpts,}
  },
  setup() {  
    

    return { 
      //slideOpts,
      //messages : 5
     };
  },
  methods: {
    async showMessage(m: any)
    {
      const modal = await modalController
        .create({
          component: UserMessageModal,
          cssClass: 'my-custom-class',
          componentProps: {
            message: m,
          },
        })
      return modal.present();
    }
  },
  mounted()
  {
    //mySwiper.reInit(true)
    console.log("mounted usermesage", (this.$refs.mySwiper as any).$el);
    
    //this.$nextTick( () => { (this.$refs.mySwiper as any).$el.update()});
    setTimeout( () => { (this.$refs.mySwiper as any).$el.update()}, 500 );
    //this.$nextTick( () => { (this.$refs.mySwiper as any).$el.pagination.update()});
    //this.$nextTick().then(() =>this.$nextTick(  () => { (this.$refs.mySwiper as any).$el.update()}));
  },
  
});
</script>

<style scoped>

ion-slides
{
  --bullet-background	:black;
}
ion-slide
{
  width: unset;
}

.no-messages-card
{
  width: 250px;
  height:150px;
  margin: 5px 10px;
  display: flex;
}

.no-messages-text
{
  margin: auto;
  padding: 10px;
  width: 100%;
  
  font-size: 2em;
}

.swiper-pagination-bullet {
  background: white !important;
  opacity: 0.2 !important;
}

.swiper-pagination-bullet-active {
  opacity: 0.9 !important;
  background: white !important;
}
</style>