<template>
  <div>
    <ion-button class="btn" fill="clear"  router-link="/dashboard" >
      <ion-icon :icon="arrowBackOutline"></ion-icon>
    </ion-button> 
  </div>
</template>

<script lang="ts">
//import config from '@/_services/config';
import {  IonButton } from '@ionic/vue';
//import { key } from 'ionicons/icons';
import { defineComponent } from 'vue';
//import { useRouter } from 'vue-router';
import { arrowBackOutline} from 'ionicons/icons';  


export default defineComponent({
  name: 'dashboard-btn',
  components: {
    IonButton,
  },
  setup() {  
    return { arrowBackOutline };
  },
  computed:
  {
    
  },
  methods: {
    
  }
});
</script>

<style scoped>

.btn
{
    width: 100%;
    height: 100%;
    //background: url( '../../public/assets/img/PICT_Compte_R.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    --padding-start : 0px;
    --padding-end : 0px;
    font-size: 20px;
}

</style>