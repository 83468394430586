


export interface EmailOptions {
    to?: Array<string>; // email addresses for TO field
    cc?: Array<string>; // email addresses for CC field
    bcc?: Array<string>; // email addresses for BCC field
    attachments?: Array<string>; // file paths or base64 data streams
    subject?: string; // subject of the email
    body?: string; // email body (for HTML, set isHtml to true)
    isHtml?: boolean; // indicates if the body is HTML or plain text
    type?: string; // content type of the email (Android only)
    app?: string;
  }

export class EmailPlugin {
    
    open(options?: EmailOptions | null): Promise<any> {
        let url = 'mailto:';
        if (!options) {
            window.open(url);
            return Promise.resolve({});
        }
        const keys = Object.keys(options);
        for (const key of keys) {
            let value;
            if (key === 'to') {
                url = url + `${((options as any)[key] as string[]).join(';')}?`;
            } else if (key === 'cc' || key === 'bcc') {
                value = ((options as any)[key] as string[]).join(';');
                url = url + `${key}=${value}&`;
            } else {
                value = (options as any)[key];
                url = url + `${key}=${value}&`;
            }
        }
        window.open(url);
        return Promise.resolve({});
    }

}

const EmailPluginWeb = new EmailPlugin();

export { EmailPluginWeb };
