

import {  
  IonList,
  IonItem,
  IonLabel,
  IonNote,

 } from '@ionic/vue';
 
import { defineComponent } from 'vue';



export default defineComponent({
  name: 'leaflet-poi-detail',
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonNote,
  
  },
  props: {
    data: {type:Object},
    debug: {type:Boolean, default : false},
  },
  setup() {  
    return { keys :5 };
  },
  computed: {
    currentLanguage(): any
    {
      return this.$i18n.locale;
    },
    currentLanguageTranslation()
    {
      return require(`../../locales/osm/tags/${this.currentLanguage}.json`);
      //return this.$i18n.locale;
    }
  },
  methods: {
    getTagTranslation(tag,v)
    {
      const loc = this.currentLanguageTranslation;
      //console.log(loc);
      const tv = `tag:${tag}=${v}`;
      const t = `tag:${tag}`;
      if(tv in loc)
      {
        return loc[tv].message;
      }
      
      if(t in loc)
      {
        return loc[t].message;
      }

      return tag;
    }
  },
});
