import { userService } from '../_services';
import  router  from '../router';
//import { useRouter } from 'vue-router';

const user = JSON.parse(localStorage.getItem('user') || '{}');
const company = JSON.parse(localStorage.getItem('company') || '{}');
const portfolios = JSON.parse(localStorage.getItem('portfolios') || '{}');
const messages = JSON.parse(localStorage.getItem('messages') || '{}');
const realEstateAssets = JSON.parse(localStorage.getItem('realEstateAssets') || '{}');
const token = JSON.parse(localStorage.getItem('token') || '{}');

const initialState = token
    ? { status: { loggedIn: true }, user, token, company ,portfolios,realEstateAssets,messages}
    : { status: {loggedIn: false}, user: null, company:null,portfolios:null,realEstateAssets :null, messages:null};

export const authentication = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ dispatch, commit }, { email, password }) {
            commit('loginRequest');

            userService.login(email, password)
                .then(
                    user => {
                        commit('loginSuccess', user);
                        router.push('/');
                    },
                    error => {
                        commit('loginFailure', error);
                        let msg='';
                        if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        
                        msg = error.response.data;
                        if( (typeof msg) !== 'string')
                        {
                            msg =  JSON.stringify(error.response.data);
                            msg =  error.response.data.message;
                        }
                        
                        } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log(error.request);
                        msg = error.request;
                        } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                        msg = error.message;
                        }

                        
                        dispatch('alert/error', {title:'login error', message: msg}, { root: true });
                    }
                );
        },
        logout({ commit }) {
            console.log('committing logout');
            commit('logout');
            //router.push('/');
            userService.logout().finally(() =>
            {
                console.log('committing logout finally');
                router.push('/login');
                
             });
            
            
        },
        getUser({ commit, dispatch }) {
            userService.getUser().then((user) =>
            {
                commit('user', {user:user});
            }).catch( (error)=>
            {
                console.log("Get user error ", error);
                if(error.response && error.response.status === 401 )
                {
                    console.log("token error getUser");
                    //router.push('/');
                    dispatch('logout');
                    //dispatch('alert/error', {title:'error', message: 'unauthenficated user'}, { root: true });

                }
            })
            ;
        },
        getCompany({ commit, dispatch }) {
            userService.getCompany().then((company) =>
            {
                commit('company', {company:company});
            }).catch( (error)=>
            {
                console.log("Get company error ", error);
                if(error.response && error.response.status === 401 )
                {
                    console.log("token error getCompany");
                    //router.push('/');
                    dispatch('logout');
                    //dispatch('alert/error', {title:'error', message: 'unauthenficated user'}, { root: true });

                }
            })
            ;
        },
        getPortfolios({ commit, dispatch }) {
            userService.getPortfolios().then((portfolios) =>
            {
                commit('portfolios', {portfolios:portfolios});
            }).catch( (error)=>
            {
                console.log("Get portfolios error ", error);
                if(error.response && error.response.status === 401 )
                {
                    console.log("token error getPortfolios");
                    //router.push('/');
                    dispatch('logout');
                    //dispatch('alert/error', {title:'error', message: 'unauthenficated user'}, { root: true });

                }
            })
            ;
        },
        getMessages({ commit, dispatch }) {
            userService.getMessages().then((messages) =>
            {
                commit('messages', {messages:messages});
            }).catch( (error)=>
            {
                console.log("Get messages error ", error);
                if(error.response && error.response.status === 401 )
                {
                    console.log("token error getMessages");
                    //router.push('/');
                    dispatch('logout');
                    //dispatch('alert/error', {title:'error', message: 'unauthenficated user'}, { root: true });

                }
            })
            ;
        },
        getRealEstateAssets({ commit, dispatch }) {
            userService.getRealEstateAssets().then((realEstateAssets) =>
            {
                commit('realEstateAssets', {realEstateAssets:realEstateAssets});
            }).catch( (error)=>
            {
                console.log("Get realEstateAssets error ", error);
                if(error.response && error.response.status === 401 )
                {
                    console.log("token error getRealEstateAssets");
                    //router.push('/');
                    dispatch('logout');
                    //dispatch('alert/error', {title:'error', message: 'unauthenficated user'}, { root: true });

                }
            })
            ;
        },

    },
    mutations: {
        loginRequest(state) {
            state.status = { loggingIn: true };
            state.user = null;
            state.token = null;
        },
        loginSuccess(state, payload) {
            state.status = { loggedIn: true };
            state.user = payload.user;
            state.token = payload.token;
        },
        loginFailure(state) {
            state.status = { loggedIn: false};
            state.user = null;
            state.token = null;
        },
        user(state, payload)
        {
            //if(state.status)
            state.user = payload.user; 
        },
        company(state, payload)
        {
            //if(state.status)
            state.company = payload.company; 
        },
        portfolios(state, payload)
        {
            //if(state.status)
            state.portfolios = payload.portfolios; 
        },
        messages(state, payload)
        {
            //if(state.status)
            state.messages = payload.messages; 
        },
        realEstateAssets(state, payload)
        {
            //if(state.status)
            state.realEstateAssets = payload.realEstateAssets; 
        },
        logout(state) {
            state.status = { loggedIn: false};
            state.user = null;
            state.company = null;
            state.portfolios = null;
            state.messages = null;
            state.realEstateAssets = null;
            state.token = null;
            //localStorage.removeItem('user');
            //localStorage.removeItem('token');
        }
    }
}