<template>
  <ion-item  class="item" lines="full">
    <ion-label class="label label-name" slot="start">{{label}}</ion-label>
    <ion-label class="label label-value" slot="end">{{formattedValue}}</ion-label>
  </ion-item>
</template>

<script lang="ts">
import {  IonItem, IonLabel} from '@ionic/vue';
import { defineComponent } from 'vue';

import moment from 'moment';
import numeral from 'numeral';

export default defineComponent({
  name: 'data-key-item',
  components: {
    IonItem,
    IonLabel
  },
  props:
  {
    type: String,
    label: String,
    value: String,
  },
  setup() {  
    return {  };
  },
  computed: {
    formattedValue(): any
    {
      this.$i18n.locale;
      switch(this.type)
      {
        
        case 'Rent' :
        case 'Investment' :
        case 'Distribution' :
        case 'BuyingPrice' : 
        case 'RentalArea' : return numeral(Number(this.value)).format('0[.]00a');
        case 'Walt' : return numeral(Number(this.value)).format('0[.]00');
        case 'BuyingDate' : return moment(this.value).format('L');
        
      }
      return this.value;
    }
  },
  methods: {
    
  },
});
</script>

<style scoped>

.item
{
  --min-height: 30px;
  font-size: 0.8em;
  --background : inherit;
  
}
.label
{
  padding: 0;
}

.label-name
{
  padding-left: 5px;
  font-weight: 700;
}

.label-value
{
  min-width: 130px;
  text-align: end;
  padding-right: 5px;
  font-style: italic;
  margin-inline-start: 5px;
  white-space: normal;
}

.left
{
  float: left;
}

.right
{
  float: right;
}



</style>