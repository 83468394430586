

import { EmailPlugin } from '@/_helpers/EmailPlugin';
import { defineComponent } from 'vue';
import DataKeyItem from './DataKeyIconItem.vue';


export default defineComponent({
  name: 'data-key-icon',
  components: {
    
    
    DataKeyItem
    
  },
  props: {
    data: {type:Array}
  },
  emits: ['show'],
  methods: {
    
    show(key: string)
    {
      //console.log("[DataKey Icon] show",key);
      this.$emit('show',key)
    }
  },
});
