<template>
  <ion-header>
    <ion-toolbar>
        <ion-title>{{ $t('components.glossaireModal.sectionTitle') }}</ion-title>
        <ion-buttons slot="end">
            <ion-button color="primary" @click="dismissModal()">{{$t('components.glossaireModal.closeBtn')}}</ion-button>
        </ion-buttons>      
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list>
      <template v-for="i in items" :key="i">
        <ion-item>
          <ion-label>
            <h2 class="label-title" >{{ $t('components.glossaireModal.content.'+i+'.title')}}</h2>
            <p class="label-description" >{{ $t('components.glossaireModal.content.'+i+'.description')}}</p>
          </ion-label>
        </ion-item>
      </template>
    </ion-list>
  </ion-content>
</template>

<script>
import
{
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  modalController
} from '@ionic/vue';

import { defineComponent } from 'vue';





export default defineComponent({
  name: 'DocumentModal',
  components: { 
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButton,
    IonButtons,
    },
  props: {
    documents:{type:Array}
  },
  data() {
    return {
      items: [
        'transaction',
        'cleEnMain',
        'comptePropre',
        'valeurLocative',
        'valeurLocativeMoyenne',
      ]
    }
  },
  
  methods:
  {
    dismissModal()
    {
        modalController.dismiss();
    },
  }
  
});
</script>

<style scoped>

.label-title
{
  font-weight: 700;
  font-size: 1.2em;
  color: darkgray;
}
.label-description
{
  color: black;
  text-align: justify;
  overflow-wrap: normal;
  white-space: normal;
}

.label-filename
{
    font-weight: 600;
}

.label-date
{
    text-align: end;
    max-width: 80px;
}

</style>