<template>
  <div class="welcome-container">
    <div class="welcome-popup">
      <div class="welcom-text">
        <p><b>{{$t('components.welcomPopup.welcome')}} {{username}}</b></p>
        <br>
        <p class="pre-formatted">{{$t('components.welcomPopup.text')}}</p>
      </div>
      <ion-button class="btn" fill="outline"  @click="$emit('close')">{{$t('components.welcomPopup.btn')}}</ion-button>
    </div>
  </div>
</template>

<script lang="ts">
import {  IonButton } from '@ionic/vue';
import { defineComponent } from 'vue';
import { mapState } from 'vuex'



export default defineComponent({
  name: 'welcome-popup',
  emits:['close'],
  components: {
    IonButton,
  },
  setup() {  
    return {  };
  },
  computed: {
    ...mapState<any>({
        user: state =>  state.authentication.user,
        username: function(){
          if(this.user)
            return this.user.first_name;

          return "no user";
          },
    })
  },
  methods: {
    
  },
});
</script>

<style scoped>
.welcome-container
{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  z-index: 1000;
  background-color:rgba(0, 0, 0, 0.301);
}

.welcome-popup
{

  background: white;
  box-shadow: 10px 5px 5px rgba(61, 61, 61, 0.74);
  
  margin: auto;
  padding: 30px 10px;
  width: 400px;

  align-self: center;

  
}

.btn
{
  border: 1px solid var(--ion-color-primary);
  border-radius: 30px;
  --border-radius:30px;
  --background: white;
  color: var(--ion-color-primary);
}


.pre-formatted {
  white-space: pre-wrap;
}

</style>