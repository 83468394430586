<template>
  <ion-page>
    
    <ion-content :fullscreen="true">
      <ion-grid fixed="true">
        <div id="container">
          <div class="lang">
            <div class="lang-btn">Fr</div>
          </div>
          <div class="logo">

          </div>
          <div class="loging-panel">
            <!-- Default Input -->
            <ion-list>
              <!-- Input with placeholder -->
              <ion-input class="login-input" placeholder="email"></ion-input>
              <ion-input class="login-input"  placeholder="mot de passe" type="password"></ion-input>

              <ion-button class="login-btn"  router-link="/dashboard">Login</ion-button>
            </ion-list>
          </div>
          <!--<ion-button router-link="/home">Go to Home</ion-button>
          <ion-button router-link="/login">Go to Login</ion-button>
          <ion-button router-link="/map">Go to Map</ion-button>
          <ion-button router-link="/dashboard">Go to Dashboard</ion-button> -->
          <div class="footer">
            <a href='#'>Réinitialisé votre mot de passe</a>
          </div>
        </div>
      </ion-grid>

        
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonGrid, IonPage, IonButton, IonList, IonInput } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonGrid,
    IonList,
    IonPage,
    //IonItem,
    //IonLabel,
    IonButton,
    IonInput
  },
  setup() {
      const router = useRouter();
      return { router };
    }
});
</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
}


#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: var(--ion-color-light);
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

.lang
{
  height: 150px;
  position: relative;
}

.lang-btn
{
  position: absolute;
  width: 80px;
  height: 80px;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  text-align: center;
}
.logo
{
  margin: 40px;
  height: 80px;
  background: url( '../../public/assets/img/logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.loging-panel
{
  margin: auto;
  position: absolute;
  top: 300px;
  bottom: 4em;
  left: 30px;
  right: 30px;
  display: flex;

}

.loging-panel ion-list
{
  width: 100%;
  margin: auto;
}

ion-input
{
  color: black;
  background-color:var(--ion-color-light);
  margin: 5px 0px;

}

.footer
{
  position: absolute;
  width: 100%;
  bottom: 0px;
  height: 3em;
  text-align: center;
}
</style>