export const alert = {
    namespaced: true,
    state: {
        type: null,
        title: null,
        message: null,
        paths:[],
    },
    actions: {
        success({ commit }, payload) {
            commit('success', payload);
        },
        error({ commit }, payload) {
            commit('error', payload);
        },
        clear({ commit }) {
            commit('clear');
        }
    },
    mutations: {
        success(state, payload) {
            state.type = 'alert-success';
            state.title = payload.title;
            state.message = payload.message;
            state.paths = payload.paths;
        },
        error(state, payload) {
            state.type = 'alert-danger';
            state.title = payload.title;
            state.message = payload.message;
            state.paths = payload.paths;
        },
        clear(state) {
            state.type = null;
            state.title = null
            state.message = null;
            state.paths = [];
        }
    }
}